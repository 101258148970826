import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import board from '../../assets/buy_board.png';
import dragDrop from '../../assets/drag-drop.mp4';
import editBuyModal from '../../assets/edit_buy_modal.png';
import editRentModal from '../../assets/edit_rent_modal.png';
import './ServicesSlider.scss';

import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { brandColors } from '../../utils/brandColors';

export default function ServicesSlider() {
  return (
    <Swiper
      style={{
        '--swiper-navigation-color': `${brandColors.purple}`,
        '--swiper-pagination-color': `${brandColors.purple}`,
      }}
      spaceBetween={30}
      centeredSlides={true}
      autoplay={{
        delay: 4000,
        disableOnInteraction: false,
        pauseOnMouseEnter: true,
      }}
      pagination={{
        clickable: true,
      }}
      navigation={true}
      modules={[Autoplay, Pagination, Navigation]}
    >
      <SwiperSlide>
        <img src={board} alt='board' />
      </SwiperSlide>
      <SwiperSlide>
        <video width='100%' height='100%' autoPlay loop muted>
          <source src={dragDrop} type='video/mp4' />
        </video>
      </SwiperSlide>
      <SwiperSlide>
        <img src={editBuyModal} alt='buy property' />
      </SwiperSlide>
      <SwiperSlide>
        <img src={editRentModal} alt='rent property' />
      </SwiperSlide>
    </Swiper>
  );
}
