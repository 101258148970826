import { createSlice } from '@reduxjs/toolkit';
import { arrayMove } from '@dnd-kit/sortable';
import { removeAtIndex, insertAtIndex } from '../utils/array';

export const propertiesSlice = createSlice({
  name: 'properties',
  initialState: {
    activeId: null,
    activeProperty: null,
    columns: {},
    open: false,
    openEditModal: false,
    activeColumn: null,
    editingProperty: null,
    boardMetadata: {},
    columnForNewProperty: null,
    totalProperties: 0,
    maxPropertyWarningOpen: false,
    maxColumnWarningOpen: false,
  },

  reducers: {
    setMaxColumnWarning: (state) => {
      state.maxColumnWarningOpen = true;
    },

    unsetMaxColumnWarning: (state) => {
      state.maxColumnWarningOpen = false;
    },

    setMaxPropertyWarning: (state) => {
      state.maxPropertyWarningOpen = true;
    },

    unsetMaxPropertyWarning: (state) => {
      state.maxPropertyWarningOpen = false;
    },

    addPropertyBannerAlert: (state, action) => {
      let columnId = action.payload.columnId;
      state.columnForNewProperty = columnId;
    },

    removeColumn: (state, action) => {
      let columnId = action.payload.column;
      let columnArray = Object.keys(state.columns);
      let newArray = columnArray
        .filter((k) => Number(k) !== columnId)
        .reduce((obj, k) => {
          return Object.assign(obj, {
            [k]: state.columns[k],
          });
        }, {});

      const propertiesInColumn = state.columns[columnId].properties.length;
      state.totalProperties = state.totalProperties - propertiesInColumn;

      state.columns = newArray;
    },

    addNewColumn: (state, action) => {
      const newColumn = action.payload.column;
      let currentColumns = state.columns;
      currentColumns[newColumn.id] = {
        title: newColumn.name,
        id: newColumn.id,
        properties: [],
      };
      state.columns = currentColumns;
    },

    saveBoardMetadata: (state, action) => {
      state.boardMetadata = action.payload.boardMetadata;
    },

    editBoardTitle: (state) => {
      const title = state.title;
      state.title = title;
    },

    editIsFavorite: (state, action) => {
      const isFavorite = action.payload.is_favorite;
      const columnId = action.payload.column;
      const propertyId = action.payload.propertyId;

      let columnProperties = state.columns[columnId].properties;

      for (let i = 0; i < columnProperties.length; i++) {
        if (columnProperties[i].id === propertyId) {
          state.columns[columnId].properties[i].is_favorite = isFavorite;
          break;
        }
      }
    },

    editProperty: (state, action) => {
      const propertyId = action.payload.propertyId;
      const columnId = state.activeColumn;
      const location = action.payload.location;
      const buyPrice = action.payload.buy_price;
      const rentPrice = action.payload.rent_price;
      const rentPriceType = action.payload.rent_price_type;
      const bedrooms = action.payload.bedrooms;
      const bathrooms = action.payload.bathrooms;
      const propertyType = action.payload.property_type;
      const latestBid = action.payload.latest_bid;
      const agentName = action.payload.agent_name;
      const agentEmail = action.payload.agent_email;
      const agentContactNumber = action.payload.agent_contact_number;
      const likes = action.payload.likes;
      const dislikes = action.payload.dislikes;
      const notes = action.payload.notes;
      const groundRent = action.payload.ground_rent;
      const size = action.payload.size;
      const sizeType = action.payload.size_type;
      const url = action.payload.url;
      const viewingDate = action.payload.viewing_date;
      const viewingTime = action.payload.viewing_time;
      const addedDate = action.payload.added_date;
      const tenure = action.payload.tenure;
      const serviceCharge = action.payload.service_charge;
      const garden = action.payload.garden;
      const needsRefurbishment = action.payload.needs_refurbishment;
      const parkingSpace = action.payload.parking_space;
      const leaseLength = action.payload.lease_length;
      const letAvailableDate = action.payload.let_available_date;
      const deposit = action.payload.deposit;
      const letType = action.payload.let_type;
      const furnishType = action.payload.furnish_type;
      const houseShare = action.payload.house_share;
      const studentAccommodation = action.payload.student_accommodation;
      const retirementHome = action.payload.retirement_home;
      const petsAllowed = action.payload.pets_allowed;
      const newHome = action.payload.new_home;

      let columnProperties = state.columns[columnId].properties;

      for (let i = 0; i < columnProperties.length; i++) {
        if (columnProperties[i].id === propertyId) {
          state.columns[columnId].properties[i].location = location;
          state.columns[columnId].properties[i].buy_price = buyPrice;
          state.columns[columnId].properties[i].rent_price = rentPrice;
          state.columns[columnId].properties[i].rent_price_type = rentPriceType;
          state.columns[columnId].properties[i].bedrooms = bedrooms;
          state.columns[columnId].properties[i].bathrooms = bathrooms;
          state.columns[columnId].properties[i].property_type = propertyType;
          state.columns[columnId].properties[i].latest_bid = latestBid;
          state.columns[columnId].properties[i].agent_name = agentName;
          state.columns[columnId].properties[i].agent_email = agentEmail;
          state.columns[columnId].properties[i].agent_contact_number =
            agentContactNumber;
          state.columns[columnId].properties[i].likes = likes;
          state.columns[columnId].properties[i].dislikes = dislikes;
          state.columns[columnId].properties[i].notes = notes;
          state.columns[columnId].properties[i].ground_rent = groundRent;
          state.columns[columnId].properties[i].size = size;
          state.columns[columnId].properties[i].size_type = sizeType;
          state.columns[columnId].properties[i].url = url;
          state.columns[columnId].properties[i].viewing_date = viewingDate;
          state.columns[columnId].properties[i].viewing_time = viewingTime;
          state.columns[columnId].properties[i].added_date = addedDate;
          state.columns[columnId].properties[i].tenure = tenure;
          state.columns[columnId].properties[i].service_charge = serviceCharge;
          state.columns[columnId].properties[i].garden = garden;
          state.columns[columnId].properties[i].needs_refurbishment =
            needsRefurbishment;
          state.columns[columnId].properties[i].parking_space = parkingSpace;
          state.columns[columnId].properties[i].lease_length = leaseLength;
          state.columns[columnId].properties[i].let_available_date =
            letAvailableDate;
          state.columns[columnId].properties[i].deposit = deposit;
          state.columns[columnId].properties[i].let_type = letType;
          state.columns[columnId].properties[i].furnish_type = furnishType;
          state.columns[columnId].properties[i].house_share = houseShare;
          state.columns[columnId].properties[i].student_accommodation =
            studentAccommodation;
          state.columns[columnId].properties[i].retirement_home =
            retirementHome;
          state.columns[columnId].properties[i].pets_allowed = petsAllowed;
          state.columns[columnId].properties[i].new_home = newHome;
          break;
        }
      }
    },

    setEditingProperty: (state, action) => {
      const columnId = action.payload.columnId;
      const propertyId = action.payload.propertyId;

      let columnProperties = state.columns[columnId].properties;

      for (let i = 0; i < columnProperties.length; i++) {
        if (columnProperties[i].id === propertyId) {
          state.editingProperty = columnProperties[i];
          break;
        }
      }

      state.activeColumn = columnId;
    },

    unsetEditingProperty: (state) => {
      state.editingProperty = null;
    },

    handleOpenEditModal: (state) => {
      state.openEditModal = true;
    },

    handleCloseEditModal: (state) => {
      state.openEditModal = false;
    },

    handleOpenAddPropertyModal: (state, action) => {
      state.open = true;
      state.activeColumn = action.payload.columnId;
    },

    handleCloseAddPropertyModal: (state) => {
      state.open = false;
    },

    removeProperty: (state, action) => {
      let columnProperties = state.columns[action.payload.column].properties;
      let index = null;
      for (let i = 0; i < columnProperties.length; i++) {
        if (columnProperties[i].id === action.payload.property) {
          index = i;
          break;
        }
      }
      state.columns[action.payload.column].properties = removeAtIndex(
        state.columns[action.payload.column].properties,
        index
      );
      state.totalProperties = state.totalProperties - 1;
    },

    addNewProperty: (state, action) => {
      const newProperty = action.payload.property;
      let currentProperties = state.columns[state.activeColumn].properties;
      currentProperties.unshift(newProperty);

      state.columns[state.activeColumn].properties = currentProperties;
      state.open = false;

      state.totalProperties = state.totalProperties + 1;
      return state;
    },

    setProperties: (state, action) => {
      state.columns = action.payload.properties;
      let totalProperties = 0;
      for (const [key, value] of Object.entries(state.columns)) {
        totalProperties =
          totalProperties + state.columns[key].properties.length;
      }
      state.totalProperties = totalProperties;
    },

    handleDragStart: (state, action) => {
      const activeId = action.payload.activeId;
      const activeContainer = action.payload.activeContainer;

      // This is used to get the property object from the redux store
      // which corresponds to the property you are currently dragging.

      const column = state.columns[activeContainer];

      for (let i = 0; i < column.properties.length; i++) {
        if (column.properties[i].id === activeId) {
          state.activeProperty = column.properties[i];
          break;
        }
      }

      state.activeId = activeId;
    },

    handleDragOver: (state, action) => {
      const activeContainer = action.payload.activeContainer;
      const overContainer = action.payload.overContainer;
      const overId = action.payload.overId;

      if (!overId) {
        return;
      }

      if (activeContainer !== overContainer) {
        const activeIndex = action.payload.activeIndex;

        const overIndex =
          overId in state.columns
            ? state.columns[overContainer].properties.length + 1
            : action.payload.overIndex;

        state.columns[activeContainer].properties = removeAtIndex(
          state.columns[activeContainer].properties,
          activeIndex
        );

        state.columns[overContainer].properties = insertAtIndex(
          state.columns[overContainer].properties,
          overIndex,
          state.activeProperty
        );
      }
    },

    handleDragCancel: (state) => {
      state.activeId = null;
    },

    handleDragEnd: (state, action) => {
      const overId = action.payload.overId;

      if (!overId) {
        return;
      }

      const activeContainer = action.payload.activeContainer;
      const overContainer = action.payload.overContainer;
      const activeIndex = action.payload.activeIndex;
      const overIndex =
        overId in state.columns
          ? state.columns[overContainer].properties.length + 1
          : action.payload.overIndex;

      state.columns[activeContainer].properties = arrayMove(
        state.columns[activeContainer].properties,
        activeIndex,
        overIndex
      );

      state.activeProperty.property_column_id = state.columns[overContainer].id;
    },

    updatePropertyPositions: (state, action) => {
      const activeContainer = action.payload.activeContainer;
      // Updates properties position in the same column
      const len = state.columns[activeContainer].properties.length;
      for (let i = 0; i < len; i++) {
        let property = state.columns[activeContainer].properties[i];
        property.position = i;
        state.columns[activeContainer].properties[i] = property;
        if (
          state.activeProperty !== null &&
          property.id === state.activeProperty.id
        ) {
          state.activeProperty.position =
            state.columns[activeContainer].properties[i].position;
        }
      }
    },
  },
});

export const {
  setMaxColumnWarning,
  unsetMaxColumnWarning,
  setMaxPropertyWarning,
  unsetMaxPropertyWarning,
  addPropertyBannerAlert,
  removeColumn,
  addNewColumn,
  editBoardTitle,
  saveBoardMetadata,
  editIsFavorite,
  editProperty,
  setEditingProperty,
  unsetEditingProperty,
  handleOpenEditModal,
  handleCloseEditModal,
  handleOpenAddPropertyModal,
  handleCloseAddPropertyModal,
  removeProperty,
  addNewProperty,
  setProperties,
  handleDragEnd,
  handleDragStart,
  handleDragCancel,
  handleDragOver,
  updatePropertyPositions,
} = propertiesSlice.actions;

export default propertiesSlice.reducer;
