export const modalContainerStyles = (theme) => ({
  [theme.breakpoints.up('xs')]: {
    display: 'flex',
    flexDirection: 'column',
  },
  [theme.breakpoints.up('lg')]: {
    display: 'flex',
    flexDirection: 'row',
  },
});

export const leftSideShortTextStyles = (theme) => ({
  [theme.breakpoints.up('xs')]: {
    display: 'flex',
    flexDirection: 'column',
  },
  [theme.breakpoints.up('sm')]: {
    display: 'flex',
    flexDirection: 'row',
  },
  [theme.breakpoints.up('md')]: {
    display: 'flex',
    flexDirection: 'row',
  },
  [theme.breakpoints.up('lg')]: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  [theme.breakpoints.up('xl')]: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
  },
});

export const leftSideShortTextLeftStyles = (theme) => ({
  [theme.breakpoints.up('sm')]: {
    width: '50%',
  },
  [theme.breakpoints.up('md')]: {
    marginRight: '8px',
  },
  [theme.breakpoints.up('lg')]: {
    marginRight: '8px',
  },
  [theme.breakpoints.up('xl')]: {
    marginRight: '8px',
  },
});

export const leftSideShortTextRightStyles = (theme) => ({
  [theme.breakpoints.up('xs')]: {
    marginTop: '8px',
  },
  [theme.breakpoints.up('sm')]: {
    marginTop: '0',
    marginLeft: '8px',
    width: '50%',
  },
});

export const checkboxContainerStyles = (theme) => ({
  [theme.breakpoints.up('sm')]: {
    display: 'flex',
    flexDirection: 'row',
  },
  [theme.breakpoints.up('md')]: {
    display: 'flex',
    flexDirection: 'row',
  },
  [theme.breakpoints.up('lg')]: {
    display: 'flex',
    flexDirection: 'row',
  },
});

export const checkboxContainerLeftStyles = (theme) => ({
  [theme.breakpoints.up('sm')]: {
    marginRight: '16px',
  },
});

export const checkboxStyles = (theme) => ({
  [theme.breakpoints.up('xs')]: {
    display: 'flex',
    flexDirection: 'column',
  },
});

export const leftSideStyles = (theme) => ({
  [theme.breakpoints.up('lg')]: {
    width: '50%',
  },
});

export const rightSideStyles = (theme) => ({
  [theme.breakpoints.up('xs')]: {
    marginTop: '8px',
  },
  [theme.breakpoints.up('lg')]: {
    marginLeft: '16px',
    marginTop: '0',
    width: '50%',
  },
});
