import {
  DndContext,
  KeyboardSensor,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
  DragOverlay,
} from '@dnd-kit/core';
import { sortableKeyboardCoordinates } from '@dnd-kit/sortable';

import Column from '../Column/Column';
import CardItem from '../CardItem/CardItem';

import { useDispatch, useSelector, useStore } from 'react-redux';
import {
  setProperties,
  handleDragEnd,
  handleDragStart,
  handleDragCancel,
  handleDragOver,
  updatePropertyPositions,
  saveBoardMetadata,
} from '../../store/properties';

import './Board.scss';
import { useEffect } from 'react';
import { Box } from '@mui/material';
import axios from 'axios';
import EditPropertyModal from '../EditPropertyModal/EditPropertyModal';
import { useAuth0 } from '@auth0/auth0-react';
import BoardTitle from '../BoardTitle/BoardTitle';
import NotFound from '../Errors/NotFound';
import AddColumnButton from '../AddColumnButton/AddColumnButton';
import MaxColumnBanner from '../MaxColumnBanner/MaxColumnBanner';
import MaxPropertyBanner from '../MaxPropertyBanner/MaxPropertyBanner';
import SideBar from '../../routes/SideBar/SideBar';
import AddPropertyModal from '../AddPropertyModal/AddPropertyModal';
import axiosRetry from 'axios-retry';

export default function Board() {
  const columns = useSelector((state) => state.properties.columns);
  const activeId = useSelector((state) => state.properties.activeId);
  const activeProperty = useSelector(
    (state) => state.properties.activeProperty
  );
  const maxPropertyWarningOpen = useSelector(
    (state) => state.properties.maxPropertyWarningOpen
  );
  const maxColumnWarningOpen = useSelector(
    (state) => state.properties.maxColumnWarningOpen
  );

  const dispatch = useDispatch();
  const store = useStore();

  const { isAuthenticated, getAccessTokenSilently } = useAuth0();
  const devUrl = process.env.REACT_APP_API_URL;

  axiosRetry(axios, { retries: 3 });

  useEffect(() => {
    getAccessTokenSilently().then((result) => {
      const axiosConfig = {
        headers: {
          Authorization: `Bearer ${result}`,
        },
      };

      axios
        .get(`${devUrl}/board/`, axiosConfig)
        .then((response) => {
          const data = response.data;
          dispatch(saveBoardMetadata({ boardMetadata: data[0] }));

          const boardId = data[0].id;
          axios
            .get(`${devUrl}/board/${boardId}/properties/`, axiosConfig)
            .then((response) => {
              const data = response.data;
              dispatch(setProperties({ properties: data }));
            })
            .catch((error) => {
              console.log(error);
            });
        })
        .catch((error) => {
          console.log(error);
        });
    });

    window.gtag('event', 'conversion', {
      send_to: 'AW-11285032437/zXmWCIbIl84YEPXbj4Uq',
    });
  }, [dispatch, getAccessTokenSilently]);

  function updatePropertiesColumn(request) {
    getAccessTokenSilently().then((result) => {
      localStorage.setItem('token', result);
    });

    const axiosConfig = {
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      },
    };

    axios
      .put(`${devUrl}/properties/column/`, request, axiosConfig)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function updatePositionsInBackend(request) {
    getAccessTokenSilently().then((result) => {
      localStorage.setItem('token', result);
    });

    const axiosConfig = {
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      },
    };

    axios
      .post(`${devUrl}/properties/order/`, request, axiosConfig)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const sensors = useSensors(
    useSensor(MouseSensor, {
      // enables iconbutton click
      activationConstraint: {
        distance: 8,
      },
    }),
    useSensor(TouchSensor, {
      activationConstraint: {
        distance: 8,
      },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
      activationConstraint: {
        distance: 8,
      },
    })
  );

  return (
    <Box>
      {isAuthenticated ? (
        <DndContext
          sensors={sensors}
          onDragStart={(event) => {
            dispatch(
              handleDragStart({
                activeId: event.active.id,
                activeContainer: event.active.data.current.sortable.containerId,
              })
            );
          }}
          onDragOver={(event) => {
            dispatch(
              handleDragOver({
                overId: event.over?.id || null,
                activeContainer: event.active.data.current.sortable.containerId,
                overContainer:
                  event.over?.data.current?.sortable.containerId ||
                  event.over?.id ||
                  null,
                activeIndex: event.active.data.current.sortable.index,
                overIndex: event.over?.data.current?.sortable.index || null,
              })
            );
          }}
          onDragCancel={() => {
            dispatch(
              handleDragCancel({
                activeId: null,
              })
            );
          }}
          onDragEnd={(event) => {
            dispatch(
              handleDragEnd({
                activeId: event.active.id,
                overId: event.over?.id || null,
                activeIndex: event.active.data.current.sortable.index,
                activeContainer: event.active.data.current.sortable.containerId,
                overContainer:
                  event.over?.data.current?.sortable.containerId ||
                  event.over?.id ||
                  null,
                overIndex: event.over?.data.current?.sortable.index || null,
              })
            );
            dispatch(
              updatePropertyPositions({
                activeContainer: event.active.data.current.sortable.containerId,
              })
            );

            const updatedState = store.getState();
            const properties = updatedState.properties;

            const request = {
              property_id: properties.activeProperty.id,
              property_column_id: properties.activeProperty.property_column_id,
            };
            updatePropertiesColumn(request);

            // Update the position of the properties in the active column
            const activeContainer =
              event.active.data.current.sortable.containerId;
            let updatePositionsRequest = [];
            let propertiesLength =
              properties.columns[activeContainer].properties.length;
            let activeProperties =
              properties.columns[activeContainer].properties;

            for (let i = 0; i < propertiesLength; i++) {
              updatePositionsRequest.push({
                property_id: activeProperties[i].id,
                position: activeProperties[i].position,
              });
            }
            updatePositionsInBackend(updatePositionsRequest);
          }}
        >
          <AddPropertyModal />
          <EditPropertyModal />
          <Box>
            <Box className='board-container'>
              <SideBar />
              <Box className='main-board'>
                <Box className='board-header'>
                  <Box className='board-title'>
                    <BoardTitle />
                  </Box>
                  <Box className='board-alert'>
                    {maxColumnWarningOpen && <MaxColumnBanner />}
                  </Box>
                  <Box className='board-alert'>
                    {maxPropertyWarningOpen && <MaxPropertyBanner />}
                  </Box>
                </Box>
                <Box className='board-column'>
                  {Object.keys(columns).map((columnId) => (
                    <Column
                      id={columns[columnId].id}
                      key={columnId}
                      properties={columns[columnId].properties}
                      title={columns[columnId].title}
                    />
                  ))}
                  {Object.keys(columns).length !== 0 ? (
                    <Box>
                      {Object.keys(columns).length < 6 ? (
                        <AddColumnButton />
                      ) : null}
                    </Box>
                  ) : null}
                </Box>
              </Box>
            </Box>
            <DragOverlay>
              {activeId ? (
                <CardItem
                  id={activeId}
                  sortedProperty={activeProperty}
                  dragOverlay
                />
              ) : null}
            </DragOverlay>
          </Box>
        </DndContext>
      ) : (
        <NotFound />
      )}
    </Box>
  );
}
