export const modalContainerStyles = (theme) => ({
  [theme.breakpoints.up('xs')]: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '70%',
    minHeight: 230,
    bgcolor: 'background.paper',
    border: '1px solid #000000',
    borderRadius: 2,
    boxShadow: 24,
    p: 2,
    overflow: 'scroll',
  },
  [theme.breakpoints.up('sm')]: {
    width: '60%',
    p: 3,
  },
  [theme.breakpoints.up('md')]: {
    width: '50%',
    p: 3,
  },
  [theme.breakpoints.up('lg')]: {
    width: '40%',
    p: 3,
  },
  [theme.breakpoints.up('xl')]: {
    width: '30%',
    p: 3,
  },
});

export const modalTitle = (theme) => ({
  [theme.breakpoints.up('xs')]: {
    fontSize: 14,
  },
  [theme.breakpoints.up('sm')]: {
    fontSize: 16,
  },
  [theme.breakpoints.up('md')]: {
    fontSize: 20,
  },
});

export const buttonContainer = (theme) => ({
  [theme.breakpoints.up('xs')]: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '8px',
  },
  [theme.breakpoints.up('sm')]: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '16px',
  },
});
