import {
  Button,
  IconButton,
  Menu,
  MenuItem,
  Popover,
  Tooltip,
  Typography,
} from '@mui/material';
import { Fragment, useState } from 'react';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';
import { useDispatch, useStore } from 'react-redux';
import {
  removeColumn,
  unsetMaxColumnWarning,
  unsetMaxPropertyWarning,
} from '../../store/properties';

export default function ColumnMenuDropdown({
  columnId,
  name,
  handleEditColumnTitle,
}) {
  const store = useStore();

  const [anchorEl, setAnchorEl] = useState(null);

  const openMenu = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [deleteColumnPopover, setDeleteColumnPopover] = useState(null);
  const handleDeleteColumnPopoverClick = (event) => {
    setDeleteColumnPopover(event.currentTarget);
  };

  const handleDeleteColumnPopoverClose = () => {
    setDeleteColumnPopover(null);
  };

  const openPopover = Boolean(deleteColumnPopover);
  const popoverId = openPopover ? 'simple-popover' : undefined;

  const dispatch = useDispatch();
  const { getAccessTokenSilently } = useAuth0();
  const devUrl = process.env.REACT_APP_API_URL;

  function handleRemoveColumn() {
    getAccessTokenSilently().then((result) => {
      localStorage.setItem('token', result);
    });

    const axiosConfig = {
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      },
    };

    axios
      .delete(`${devUrl}/properties/column/${columnId}/`, axiosConfig)
      .then((response) => {
        response = response.data;
        dispatch(removeColumn({ column: columnId }));

        const updatedState = store.getState();
        const totalColumns = Object.entries(
          updatedState.properties.columns
        ).length;

        const totalProperties = updatedState.properties.totalProperties;

        if (totalColumns < 6) {
          dispatch(unsetMaxColumnWarning());
        }

        if (totalProperties < 10) {
          dispatch(unsetMaxPropertyWarning());
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  return (
    <Fragment>
      <Tooltip title='Column settings'>
        <IconButton aria-label='settings' onClick={handleClick}>
          <MoreVertIcon />
        </IconButton>
      </Tooltip>

      <Menu
        id={name}
        keepMounted
        anchorEl={anchorEl}
        open={openMenu}
        onClose={handleClose}
        disableAutoFocus={true}
        disableEnforceFocus={true}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem onClick={handleEditColumnTitle}>Edit title</MenuItem>
        <MenuItem onClick={handleDeleteColumnPopoverClick}>Delete</MenuItem>
      </Menu>

      <Popover
        id={popoverId}
        open={openPopover}
        anchorEl={deleteColumnPopover}
        onClose={handleDeleteColumnPopoverClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <div className='delete-property-popover'>
          <Typography align='center' variant='h6'>
            Delete Column
          </Typography>
          <Typography sx={{ pb: 1 }}>
            Are you sure you want to delete this column? <br />
            This will remove all the properties in this column.
          </Typography>
          <div align='center'>
            <Button
              sx={{ mr: 1 }}
              variant='contained'
              color='secondary'
              onClick={handleRemoveColumn}
            >
              Delete
            </Button>
            <Button variant='outlined' onClick={handleDeleteColumnPopoverClose}>
              Cancel
            </Button>
          </div>
        </div>
      </Popover>
    </Fragment>
  );
}
