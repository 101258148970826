import NavBar from '../NavBar/NavBar';
import './Home.scss';
import Intro from '../../components/Intro/Intro';
import { Box } from '@mui/material';
import Services from '../../components/Services/Services';
import Footer from '../../components/Footer/Footer';

export default function Home() {
  return (
    <Box>
      <NavBar />
      <Intro />
      <Services />
      <Footer />
    </Box>
  );
}
