import { useAuth0 } from '@auth0/auth0-react';
import { Box, Button, Typography } from '@mui/material';
import axios from 'axios';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { saveBoardMetadata } from '../../store/properties';
import './NavBarMenuItem.scss';

export default function NavBarMenuItem() {
  const { isAuthenticated, loginWithRedirect, logout } = useAuth0();
  const boardMetadata = useSelector((state) => state.properties.boardMetadata);
  let { title } = boardMetadata || {};
  const { getAccessTokenSilently } = useAuth0();
  const dispatch = useDispatch();
  const devUrl = process.env.REACT_APP_API_URL;
  const localUrl = process.env.REACT_APP_URL;

  useEffect(() => {
    if (!isAuthenticated) {
      return;
    }
    getAccessTokenSilently().then((result) => {
      localStorage.setItem('token', result);
    });

    const axiosConfig = {
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      },
    };

    axios
      .get(`${devUrl}/board/`, axiosConfig)
      .then((response) => {
        const data = response.data;
        dispatch(saveBoardMetadata({ boardMetadata: data[0] }));
      })
      .catch((error) => {
        console.log(error);
      });
  }, [dispatch]);

  const logoutWithRedirect = () =>
    logout({
      logoutParams: {
        returnTo: window.location.origin,
      },
    });

  return (
    <Box className='menu-item-container'>
      {!isAuthenticated && (
        <Box>
          <Link
            to={`/about`}
            style={{ textDecoration: 'none', color: 'inherit' }}
          >
            <Button color='inherit' className='menu-item-buttons'>
              <Typography noWrap={true}>About</Typography>
            </Button>
          </Link>
        </Box>
      )}
      {isAuthenticated && (
        <Link
          to={`/board`}
          style={{
            textDecoration: 'none',
            color: 'inherit',
          }}
        >
          <Button color='inherit' className='menu-item-buttons'>
            <Typography noWrap={true}>{title}</Typography>
          </Button>
        </Link>
      )}

      {isAuthenticated && (
        <Link
          to={`/account`}
          style={{ textDecoration: 'none', color: 'inherit' }}
        >
          <Button color='inherit' className='menu-item-buttons'>
            <Typography noWrap={true}>Account</Typography>
          </Button>
        </Link>
      )}
      <Box>
        {!isAuthenticated && (
          <Button
            color='inherit'
            className='menu-item-buttons'
            onClick={() =>
              loginWithRedirect({
                authorizationParams: {
                  redirect_uri: `${localUrl}/board`,
                },
              })
            }
          >
            <Typography noWrap={true}>Login/Register</Typography>
          </Button>
        )}
        {isAuthenticated && (
          <Button
            color='inherit'
            className='menu-item-buttons'
            onClick={() => logoutWithRedirect()}
          >
            <Typography noWrap={true}>Log out</Typography>
          </Button>
        )}
      </Box>
    </Box>
  );
}
