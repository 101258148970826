import { Box, IconButton, Typography } from '@mui/material';
import {
  companyStyles,
  copyrightStyles,
  footerContainerStyles,
  subTitleStyles,
  textStyles,
  titleStyles,
} from './FooterMediaQuery';
import './Footer.scss';
import { Link } from 'react-router-dom';
import habituntLogo from '../../assets/habitunt-logo.png';
import LinkedInIcon from '@mui/icons-material/LinkedIn';

export default function Footer() {
  return (
    <Box className='footer-container'>
      <Box sx={footerContainerStyles}>
        <Box className='footer-logo'>
          <img src={habituntLogo} alt='habitunt-logo' />
        </Box>
        <Box sx={companyStyles}>
          <Typography variant='h6' sx={titleStyles}>
            Habitunt
          </Typography>

          <Box>
            <Link
              to={`/about`}
              style={{ textDecoration: 'none', color: 'inherit' }}
            >
              <Typography variant='subtitle1' sx={subTitleStyles}>
                About
              </Typography>
            </Link>
          </Box>
          <Box>
            <Link
              to={`/terms-and-conditions`}
              style={{ textDecoration: 'none', color: 'inherit' }}
            >
              <Typography variant='subtitle1' sx={subTitleStyles}>
                Terms and Conditions
              </Typography>
            </Link>
          </Box>
          <Box>
            <Link
              to={`/privacy-policy`}
              style={{ textDecoration: 'none', color: 'inherit' }}
            >
              <Typography variant='subtitle1' sx={subTitleStyles}>
                Privacy Policy
              </Typography>
            </Link>
          </Box>
        </Box>
        <Box>
          <Typography variant='h6' sx={titleStyles}>
            Contact Info
          </Typography>
          <Typography variant='body1' sx={textStyles}>
            info@habitunt.com
          </Typography>
          <Box style={{ display: 'flex', alignItems: 'center' }}>
            <Typography variant='body1' sx={textStyles}>
              LinkedIn
            </Typography>
            <IconButton
              aria-label='LinkedIn'
              href='https://www.linkedin.com/company/habitunt/'
              underline='none'
              target='_blank'
              rel='noopener noreferrer'
            >
              <LinkedInIcon />
            </IconButton>
          </Box>
        </Box>
      </Box>
      <Box sx={{ m: 1 }}>
        <Typography textAlign='center' sx={copyrightStyles}>
          Copyright © {new Date().getFullYear()} Habitunt
        </Typography>
      </Box>
    </Box>
  );
}
