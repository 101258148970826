export const introContainerStyles = (theme) => ({
  [theme.breakpoints.up('xs')]: {
    display: 'flex',
    alignContent: 'center',
  },
});

export const introInfoStyles = (theme) => ({
  [theme.breakpoints.up('xs')]: {
    padding: 4,
  },
  [theme.breakpoints.up('sm')]: {
    paddingTop: 6,
    paddingRight: 12,
    paddingLeft: 12,
  },
  [theme.breakpoints.up('md')]: {
    paddingTop: 8,
    paddingRight: 24,
    paddingLeft: 24,
  },
  [theme.breakpoints.up('lg')]: {
    paddingTop: 0,
    paddingBottom: 0,
    paddingRight: 1,
    paddingLeft: 12,
  },
  [theme.breakpoints.up('xl')]: {
    paddingLeft: 24,
  },
});

export const introImageContainerStyles = (theme) => ({
  [theme.breakpoints.up('xs')]: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  [theme.breakpoints.up('lg')]: {
    paddingRight: 2,
  },
  [theme.breakpoints.up('xl')]: {
    paddingRight: 12,
  },
});

export const introTitleStyles = (theme) => ({
  [theme.breakpoints.up('xs')]: {
    fontSize: 28,
    textAlign: 'center',
  },
  [theme.breakpoints.up('sm')]: {
    fontSize: 32,
  },
  [theme.breakpoints.up('md')]: {
    fontSize: 36,
  },
  [theme.breakpoints.up('lg')]: {
    fontSize: 40,
    textAlign: 'start',
  },
  [theme.breakpoints.up('xl')]: {
    fontSize: 44,
  },
});

export const introTextStyles = (theme) => ({
  [theme.breakpoints.up('xs')]: {
    fontSize: 16,
    textAlign: 'center',
    marginTop: 1,
  },
  [theme.breakpoints.up('sm')]: {
    fontSize: 20,
  },
  [theme.breakpoints.up('md')]: {
    fontSize: 24,
  },
  [theme.breakpoints.up('lg')]: {
    fontSize: 28,
    textAlign: 'start',
  },
  [theme.breakpoints.up('xl')]: {
    fontSize: 32,
  },
});

export const introButtonContainerStyles = (theme) => ({
  [theme.breakpoints.up('xs')]: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: 1,
  },
  [theme.breakpoints.up('sm')]: {
    marginTop: 2,
  },

  [theme.breakpoints.up('lg')]: {
    alignItems: 'start',
  },
});

export const introButtonStyles = (theme) => ({
  [theme.breakpoints.up('xs')]: {
    textTransform: 'capitalize',
    fontSize: 12,
  },
  [theme.breakpoints.up('sm')]: {
    fontSize: 14,
  },
  [theme.breakpoints.up('md')]: {
    fontSize: 16,
  },
  [theme.breakpoints.up('lg')]: {
    fontSize: 18,
  },
  [theme.breakpoints.up('xl')]: {
    fontSize: 20,
  },
});
