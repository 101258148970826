import { useAuth0 } from '@auth0/auth0-react';
import { Box, TextField, Tooltip, Typography } from '@mui/material';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { editBoardTitle, saveBoardMetadata } from '../../store/properties';
import { ThemeProvider } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { customBoardTitleTheme } from './BoardTitleCustomStyles';

export default function BoardTitle() {
  const [boardData, setBoardData] = useState({});
  const [isTitleFocused, setIsTitleFocused] = useState(false);
  const { getAccessTokenSilently } = useAuth0();
  const dispatch = useDispatch();
  const outerTheme = useTheme();

  const boardMetadata = useSelector((state) => state.properties.boardMetadata);
  let { title } = boardMetadata || {};
  const devUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    setBoardData({
      ...boardData,
      editTitle: title,
    });
  }, [title]);

  const handleBoardTitleChange = (e) => {
    const { name, value } = e.target;
    setBoardData({
      ...boardData,
      [name]: value.trimStart(),
    });
  };

  function handleBlurChange() {
    if (boardData.editTitle === '') {
      setIsTitleFocused(false);
      boardData.editTitle = title;
    } else {
      handleEditBoard();
      setIsTitleFocused(false);
    }
  }

  function handleKeyChange(e) {
    if (e.keyCode === 13) {
      e.target.blur();
    }
  }

  function handleEditBoard() {
    const boardId = boardMetadata.id;

    const request = {
      user_id: 1,
      title: boardData.editTitle.trim(),
    };

    getAccessTokenSilently().then((result) => {
      localStorage.setItem('token', result);
    });

    const axiosConfig = {
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      },
    };

    axios
      .post(`${devUrl}/board/${boardId}/`, request, axiosConfig)
      .then(() => {
        dispatch(
          editBoardTitle({
            boardId: boardId,
            title: boardData.editTitle,
          })
        );

        axios.get(`${devUrl}/board/`, axiosConfig).then((response) => {
          const data = response.data;
          dispatch(saveBoardMetadata({ boardMetadata: data[0] }));
        });
      })

      .catch((error) => {
        console.log(error);
      });
  }

  return (
    <Box>
      {!isTitleFocused ? (
        <Tooltip title={boardData.editTitle}>
          <Typography
            noWrap={true}
            style={{
              maxWidth: '280px',
            }}
            onClick={() => {
              setIsTitleFocused(true);
            }}
          >
            {boardData.editTitle}
          </Typography>
        </Tooltip>
      ) : (
        <ThemeProvider theme={customBoardTitleTheme(outerTheme)}>
          <TextField
            variant='standard'
            size='small'
            style={{
              width: '280px',
            }}
            autoFocus
            name='editTitle'
            value={boardData.editTitle}
            helperText={
              boardData.editTitle ? '' : 'Board title can not be left blank'
            }
            error={boardData.editTitle ? false : true}
            inputProps={{ maxLength: 180 }}
            autoComplete='off'
            onChange={handleBoardTitleChange}
            onBlur={handleBlurChange}
            onKeyDown={handleKeyChange}
          />
        </ThemeProvider>
      )}
    </Box>
  );
}
