import {
  Card,
  CardHeader,
  Typography,
  CardActions,
  IconButton,
  Popover,
  Button,
  Tooltip,
  Box,
} from '@mui/material';

import FavoriteIcon from '@mui/icons-material/Favorite';
import DeleteIcon from '@mui/icons-material/Delete';

import './CardItem.scss';
import axios from 'axios';
import { useDispatch, useStore } from 'react-redux';
import {
  editIsFavorite,
  removeProperty,
  unsetMaxPropertyWarning,
} from '../../store/properties';
import EditPropertyButton from '../EditPropertyButton/EditPropertyButton';
import { useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { brandColors } from '../../utils/brandColors';

export default function CardItem({ sortedProperty, dragOverlay, columnId }) {
  const { location, buy_price, rent_price, is_favorite, rent_price_type } =
    sortedProperty;
  const [isFavorite, setIsFavorite] = useState(is_favorite);
  const [deletePropertyPopover, setDeletePropertyPopover] = useState(null);
  const store = useStore();

  const handleClick = (event) => {
    setDeletePropertyPopover(event.currentTarget);
  };

  const handleClose = () => {
    setDeletePropertyPopover(null);
  };

  const open = Boolean(deletePropertyPopover);
  const id = open ? 'simple-popover' : undefined;

  const style = {
    cursor: dragOverlay ? 'grabbing' : 'grab',
    boxShadow: `0 0 2px ${brandColors.purple}`,
    touchAction: 'none',
  };

  const dispatch = useDispatch();
  const { getAccessTokenSilently } = useAuth0();
  const devUrl = process.env.REACT_APP_API_URL;

  function handleRemoveProperty() {
    const propertyId = sortedProperty.id;
    getAccessTokenSilently().then((result) => {
      localStorage.setItem('token', result);
    });

    const axiosConfig = {
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      },
    };

    axios
      .delete(`${devUrl}/properties/${propertyId}/`, axiosConfig)
      .then((response) => {
        response = response.data;
        dispatch(removeProperty({ property: propertyId, column: columnId }));

        const updatedState = store.getState();
        const totalProperties = updatedState.properties.totalProperties;

        if (totalProperties < 10) {
          dispatch(unsetMaxPropertyWarning());
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function handleIsFavorite() {
    const propertyId = sortedProperty.id;

    let newFavouriteValue = !isFavorite;

    setIsFavorite(newFavouriteValue);
    const request = {
      user_id: 1,
      is_favorite: newFavouriteValue,
    };

    getAccessTokenSilently().then((result) => {
      localStorage.setItem('token', result);
    });

    const axiosConfig = {
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      },
    };
    axios
      .post(
        `${devUrl}/properties/${propertyId}/favorite/`,
        request,
        axiosConfig
      )
      .then((response) => {
        response = response.data;
        dispatch(
          editIsFavorite({
            propertyId: propertyId,
            column: columnId,
            is_favorite: newFavouriteValue,
          })
        );
      })
      .catch((error) => {
        console.log(error);
      });
  }

  return (
    <Card className='card-item-container' style={style}>
      {!rent_price ? (
        <CardHeader
          sx={{ pb: 0 }}
          action={
            <EditPropertyButton
              columnId={columnId}
              propertyId={sortedProperty.id}
            />
          }
          title={
            <Typography
              className='card-header-text'
              variant='h6'
              style={{ wordWrap: 'break-word' }}
            >
              {location}
            </Typography>
          }
          subheader={buy_price === null ? '£0' : `£${buy_price}`}
        />
      ) : (
        <CardHeader
          sx={{ pb: 0 }}
          action={
            <EditPropertyButton
              columnId={columnId}
              propertyId={sortedProperty.id}
            />
          }
          title={
            <Typography
              className='card-header-text'
              variant='h6'
              style={{ wordWrap: 'break-word' }}
            >
              {location}
            </Typography>
          }
          subheader={
            rent_price === null
              ? '£0'
              : `£${rent_price} ${rent_price_type || ''}`
          }
        />
      )}
      <CardActions>
        <Tooltip title='Favourite'>
          <IconButton aria-label='add to favorites' onClick={handleIsFavorite}>
            <FavoriteIcon
              style={{ color: isFavorite ? brandColors.pink : '' }}
            />
          </IconButton>
        </Tooltip>
        <Tooltip title='Delete'>
          <IconButton aria-label='share' onClick={handleClick}>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
        <Popover
          id={id}
          open={open}
          anchorEl={deletePropertyPopover}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
        >
          <Box className='delete-property-popover' align='center'>
            <Typography variant='h6'>Delete Property</Typography>
            <Typography sx={{ mb: 1 }}>
              Are you sure you want to delete this property?
            </Typography>
            <Box>
              <Button
                sx={{ mr: 1 }}
                variant='contained'
                color='secondary'
                onClick={handleRemoveProperty}
              >
                Delete
              </Button>
              <Button
                variant='outlined'
                color='secondary'
                onClick={handleClose}
              >
                Cancel
              </Button>
            </Box>
          </Box>
        </Popover>
      </CardActions>
    </Card>
  );
}
