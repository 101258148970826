import { Alert, Box } from '@mui/material';
import { useDispatch } from 'react-redux';
import { unsetMaxColumnWarning } from '../../store/properties';
import './MaxColumnBanner.scss';

export default function MaxColumnBanner() {
  const dispatch = useDispatch();

  return (
    <Box>
      <Alert
        className='column-banner-container'
        severity='warning'
        onClose={() => {
          dispatch(unsetMaxColumnWarning());
        }}
      >
        Maximum column number reached.
      </Alert>
    </Box>
  );
}
