export const aboutContainerStyles = (theme) => ({
  [theme.breakpoints.up('xs')]: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '90%',
  },
  [theme.breakpoints.up('sm')]: {
    width: '70%',
  },
  [theme.breakpoints.up('md')]: {
    width: '60%',
  },
  [theme.breakpoints.up('lg')]: {
    width: '50%',
  },
  [theme.breakpoints.up('xl')]: {
    width: '40%',
  },
});

export const aboutTitleStyles = (theme) => ({
  [theme.breakpoints.up('xs')]: {
    fontWeight: 'bold',
    margin: '32px 0 0 0',
  },
});

export const aboutTextStyles = (theme) => ({
  [theme.breakpoints.up('xs')]: {
    margin: '0 0 32px 0',
  },
  [theme.breakpoints.up('sm')]: {
    margin: '0 0 64px 0',
  },
});
