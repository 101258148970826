import { useDispatch, useSelector, useStore } from 'react-redux';
import {
  addPropertyBannerAlert,
  handleCloseAddPropertyModal,
  setMaxPropertyWarning,
  updatePropertyPositions,
} from '../../store/properties';
import {
  Button,
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Box,
  Typography,
  Modal,
} from '@mui/material';
import './AddPropertyModal.scss';
import { useEffect } from 'react';
import { addNewProperty } from '../../store/properties';
import axios from 'axios';
import { useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { rentPriceTypeDropdown } from '../../utils/dropdownData';
import {
  buttonContainer,
  modalContainerStyles,
  modalTitle,
} from './AddPropertyModalMediaQuery';

export default function AddPropertyModal() {
  const open = useSelector((state) => state.properties.open);
  const activeColumn = useSelector((state) => state.properties.activeColumn);
  const columns = useSelector((state) => state.properties.columns);

  const [location, setLocation] = useState('');
  const [buyPrice, setBuyPrice] = useState(0);
  const [rentPrice, setRentPrice] = useState(0);
  const [priceTypeValue, setPriceTypeValue] = useState('');
  const [rentPriceType, setRentPriceType] = useState('');
  const [disable, setDisable] = useState(true);
  const min = 0;
  const max = 1000000000;

  const dispatch = useDispatch();
  const store = useStore();
  const devUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    location && (buyPrice || rentPrice) ? setDisable(false) : setDisable(true);
  }, [location, buyPrice, rentPrice]);

  const handleLocationChange = (event) => {
    setLocation(event.target.value.trimStart());
  };

  const handleBuyPriceChange = (event) => {
    if (buyPrice < min) event.target.value = min;
    if (buyPrice > max) event.target.value = max;

    setBuyPrice(event.target.value);
  };

  const handleRentPriceChange = (event) => {
    if (rentPrice < min) event.target.value = min;
    if (rentPrice > max) event.target.value = max;

    setRentPrice(event.target.value);
  };

  const handlePriceTypeChange = (event) => {
    setPriceTypeValue(event.target.value);
  };

  const handleRentPriceTypeChange = (event) => {
    setRentPriceType(event.target.value);
  };

  const onKeyDownHandler = (event) => {
    if (['e', 'E', '+', '.', '-'].includes(event.key)) {
      event.preventDefault();
    }
  };

  const { getAccessTokenSilently } = useAuth0();

  function updatePositionsInBackend(request) {
    getAccessTokenSilently().then((result) => {
      localStorage.setItem('token', result);
    });

    const axiosConfig = {
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      },
    };

    axios
      .post(`${devUrl}/properties/order/`, request, axiosConfig)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function addProperty() {
    const columnId = columns[activeColumn].id;
    let priceType = 'BUY';
    if (priceTypeValue === 2) {
      priceType = 'RENT';
    }
    const request = {
      property_column_id: columnId,
      location: location,
      buy_price: buyPrice,
      rent_price: rentPrice,
      price_type: priceType,
      rent_price_type: rentPriceType,
    };

    getAccessTokenSilently().then((result) => {
      localStorage.setItem('token', result);
    });

    const axiosConfig = {
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      },
    };

    axios
      .post(`${devUrl}/properties/`, request, axiosConfig)
      .then((response) => {
        response = response.data;
        dispatch(addNewProperty({ property: response.property }));
        dispatch(addPropertyBannerAlert({ columnId: columnId }));
        setTimeout(() => {
          dispatch(addPropertyBannerAlert({ columnId: null }));
        }, 2000);

        let updatedState = store.getState();
        let properties = updatedState.properties;
        dispatch(
          updatePropertyPositions({
            activeContainer: updatedState.properties.activeColumn,
          })
        );

        updatedState = store.getState();
        properties = updatedState.properties;

        // Update the position of the properties in the active column
        const activeColumn = updatedState.properties.activeColumn;
        let updatePositionsRequest = [];
        let propertiesLength =
          properties.columns[activeColumn].properties.length;
        let activeProperties = properties.columns[activeColumn].properties;

        for (let i = 0; i < propertiesLength; i++) {
          updatePositionsRequest.push({
            property_id: activeProperties[i].id,
            position: activeProperties[i].position,
          });
        }
        updatePositionsInBackend(updatePositionsRequest);
        setLocation('');
        setBuyPrice('');
        setRentPrice('');
        setPriceTypeValue('');
        setRentPriceType('');

        if (updatedState.properties.totalProperties === 10) {
          dispatch(setMaxPropertyWarning());
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  return (
    <Box>
      <Modal
        open={open}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
        slotProps={{
          backdrop: { style: { backgroundColor: 'rgba(0, 0, 0, 0.5)' } },
        }}
        onClose={() => {
          dispatch(handleCloseAddPropertyModal());
          setLocation('');
          setBuyPrice('');
          setRentPrice('');
          setPriceTypeValue('');
          setRentPriceType('');
        }}
      >
        <Box
          sx={modalContainerStyles}
          className='modal-container'
          component='form'
          autoComplete='off'
          noValidate
        >
          <Typography id='modal-modal-title' variant='h6' sx={modalTitle}>
            Add a property
          </Typography>
          <Box className='textfield-container'>
            <TextField
              sx={{ mt: 1 }}
              id='outlined-basic'
              label='Address'
              variant='outlined'
              inputProps={{
                maxLength: 180,
              }}
              fullWidth
              helperText={location ? ' ' : 'Address field is required'}
              onChange={handleLocationChange}
            />
            <FormControl fullWidth sx={{ mt: 1 }}>
              <InputLabel
                id='price-select-label'
                style={{ color: 'rgba(0, 0, 0, 0.6)' }}
              >
                Select price type
              </InputLabel>
              <Select
                labelId='price-select-label'
                id='price-select'
                label='Select price type'
                value={priceTypeValue}
                onChange={handlePriceTypeChange}
              >
                <MenuItem value={1}>Buy price</MenuItem>
                <MenuItem value={2}>Rent price</MenuItem>
              </Select>
            </FormControl>
            {priceTypeValue === 1 ? (
              <TextField
                sx={{ mt: 2 }}
                id='outlined-basic'
                type='Number'
                onWheel={(event) => event.target.blur()}
                label='Buy Price'
                variant='outlined'
                helperText={buyPrice ? ' ' : 'Please enter a number'}
                inputProps={{ min, max }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start'>£</InputAdornment>
                  ),
                }}
                onKeyDown={onKeyDownHandler}
                onChange={handleBuyPriceChange}
              />
            ) : null}
            {priceTypeValue === 2 ? (
              <Box className='rent-price-container'>
                <TextField
                  sx={{ mt: 2, mr: 2 }}
                  id='outlined-basic'
                  type='Number'
                  onWheel={(event) => event.target.blur()}
                  fullWidth
                  label='Rent Price'
                  variant='outlined'
                  helperText={rentPrice ? ' ' : 'Please enter a number'}
                  onKeyDown={onKeyDownHandler}
                  inputProps={{ min, max }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position='start'>£</InputAdornment>
                    ),
                  }}
                  onChange={handleRentPriceChange}
                />
                <TextField
                  id='outlined-basic'
                  className='rent-price-type'
                  variant='outlined'
                  sx={{ mt: 2, width: '40%' }}
                  name='rentPriceType'
                  autoComplete='off'
                  value={rentPriceType || ''}
                  select
                  isclearable='true'
                  onChange={handleRentPriceTypeChange}
                >
                  {rentPriceTypeDropdown.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Box>
            ) : null}
          </Box>
          <Box className='button-container' sx={buttonContainer}>
            <Button
              className='cancel-add-property-button'
              variant='outlined'
              sx={{ mr: 1 }}
              onClick={() => {
                dispatch(handleCloseAddPropertyModal());
                setLocation('');
                setBuyPrice('');
                setRentPrice('');
                setPriceTypeValue('');
                setRentPriceType('');
              }}
            >
              Cancel
            </Button>
            <Button
              className='add-property-button'
              variant='contained'
              disabled={disable}
              onClick={addProperty}
            >
              Add
            </Button>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
}
