import NavBar from '../../routes/NavBar/NavBar';
import './NotFound.scss';

export default function NotFound() {
  return (
    <div>
      <NavBar />
      <div className='error-page'>
        <h1>Page not found!</h1>
        <p>Sorry, this page does not exist.</p>
      </div>
    </div>
  );
}
