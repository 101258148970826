import { brandColors } from '../../utils/brandColors';

export const footerContainerStyles = (theme) => ({
  [theme.breakpoints.down('300')]: {
    display: 'flex',
    flexDirection: 'column',
  },
  [theme.breakpoints.up('xs')]: {
    display: 'flex',
    justifyContent: 'space-evenly',
  },
  [theme.breakpoints.up('sm')]: {
    display: 'flex',
    justifyContent: 'center',
  },
});

export const companyStyles = (theme) => ({
  [theme.breakpoints.down('300')]: {
    margin: '8px 0',
  },
  [theme.breakpoints.up('xs')]: {
    margin: '0 8px',
  },
  [theme.breakpoints.up('sm')]: {
    margin: '0 64px',
  },
  [theme.breakpoints.up('md')]: {
    margin: '0 128px',
  },
});

export const titleStyles = (theme) => ({
  [theme.breakpoints.up('xs')]: {
    fontSize: 14,
    fontWeight: 'bold',
  },
  [theme.breakpoints.up('sm')]: {
    fontSize: 16,
  },
  [theme.breakpoints.up('md')]: {
    fontSize: 18,
  },
  [theme.breakpoints.up('lg')]: {
    fontSize: 20,
  },
  [theme.breakpoints.up('xl')]: {
    fontSize: 22,
  },
});

export const subTitleStyles = (theme) => ({
  [theme.breakpoints.up('xs')]: {
    fontSize: 12,
    '&:hover': {
      color: brandColors.purple,
    },
  },
  [theme.breakpoints.up('sm')]: {
    fontSize: 14,
  },
  [theme.breakpoints.up('md')]: {
    fontSize: 16,
  },
  [theme.breakpoints.up('lg')]: {
    fontSize: 18,
  },
  [theme.breakpoints.up('xl')]: {
    fontSize: 20,
  },
});

export const textStyles = (theme) => ({
  [theme.breakpoints.up('xs')]: {
    fontSize: 12,
  },
  [theme.breakpoints.up('sm')]: {
    fontSize: 14,
  },
  [theme.breakpoints.up('md')]: {
    fontSize: 16,
  },
  [theme.breakpoints.up('lg')]: {
    fontSize: 18,
  },
  [theme.breakpoints.up('xl')]: {
    fontSize: 20,
  },
});

export const copyrightStyles = (theme) => ({
  [theme.breakpoints.up('xs')]: {
    fontSize: 12,
  },
  [theme.breakpoints.up('sm')]: {
    fontSize: 14,
  },
  [theme.breakpoints.up('md')]: {
    fontSize: 14,
  },
  [theme.breakpoints.up('lg')]: {
    fontSize: 16,
  },
  [theme.breakpoints.up('xl')]: {
    fontSize: 16,
  },
});
