import {
  Alert,
  Box,
  Button,
  Checkbox,
  Dialog,
  IconButton,
  InputAdornment,
  Link,
  MenuItem,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useState } from 'react';
import { editProperty, handleCloseEditModal } from '../../store/properties';
import axios from 'axios';
import { useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import LinkIcon from '@mui/icons-material/Link';
import { DatePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import {
  SizeType,
  TenureType,
  buyPropertyType,
  furnishType,
  letType,
  rentPriceTypeDropdown,
  rentPropertyType,
} from '../../utils/dropdownData';
import validator from 'validator';
import './EditPropertyModal.scss';
import {
  checkboxContainerLeftStyles,
  checkboxContainerStyles,
  checkboxStyles,
  leftSideShortTextLeftStyles,
  leftSideShortTextRightStyles,
  leftSideShortTextStyles,
  leftSideStyles,
  modalContainerStyles,
  rightSideStyles,
} from './EditPropertyModalMediaQuery';

export default function EditPropertyModal() {
  const open = useSelector((state) => state.properties.openEditModal);
  const editingProperty = useSelector(
    (state) => state.properties.editingProperty
  );
  const [propertyData, setPropertyData] = useState({});
  const dispatch = useDispatch();
  const [disable, setDisable] = useState(true);
  const min = 0;
  const max = 1000000000;
  const [urlErrorMessage, setUrlErrorMessage] = useState('');
  const [viewingDateErrorMessage, setViewingDateErrorMessage] = useState('');
  const [addedDateErrorMessage, setAddedDateErrorMessage] = useState('');
  const [letAvailableDateErrorMessage, setLetAvailableDateErrorMessage] =
    useState('');
  const [timeErrorMessage, setTimeErrorMessage] = useState('');
  const [successAlert, setSuccessAlert] = useState(false);
  const [errorAlert, setErrorAlert] = useState(false);
  const [successAlertContent, setSuccessAlertContent] = useState('');
  const [errorAlertContent, setErrorAlertContent] = useState('');

  let {
    location,
    buy_price,
    rent_price,
    rent_price_type,
    bedrooms,
    bathrooms,
    property_type,
    latest_bid,
    agent_name,
    agent_email,
    agent_contact_number,
    likes,
    dislikes,
    notes,
    ground_rent,
    size,
    size_type,
    url,
    viewing_date,
    viewing_time,
    added_date,
    tenure,
    service_charge,
    garden,
    needs_refurbishment,
    parking_space,
    price_type,
    lease_length,
    let_available_date,
    deposit,
    let_type,
    furnish_type,
    house_share,
    student_accommodation,
    retirement_home,
    pets_allowed,
    new_home,
  } = editingProperty || {};

  useEffect(() => {
    if (open === true) {
      setPropertyData({
        ...propertyData,
        editLocation: location,
        editBuyPrice: buy_price,
        editRentPrice: rent_price,
        editRentPriceType: rent_price_type,
        editBedrooms: bedrooms,
        editBathrooms: bathrooms,
        editPropertyType: property_type,
        editLatestBid: latest_bid,
        editAgentName: agent_name,
        editAgentEmail: agent_email,
        editAgentContactNumber: agent_contact_number,
        editLikes: likes,
        editDislikes: dislikes,
        editNotes: notes,
        editGroundRent: ground_rent,
        editSize: size,
        editSizeType: size_type,
        editUrl: url,
        editTenure: tenure,
        editServiceCharge: service_charge,
        editGarden: garden,
        editNeedsRefurbishment: needs_refurbishment,
        editParkingSpace: parking_space,
        editViewingDate: dayjs(viewing_date, 'DD/MM/YYYY'),
        editViewingTime: dayjs(viewing_time, 'HH:mm'),
        editAddedDate: dayjs(added_date, 'DD/MM/YYYY'),
        editLeaseLength: lease_length,
        editLetAvailableDate: dayjs(let_available_date, 'DD/MM/YYYY'),
        editDeposit: deposit,
        editLetType: let_type,
        editFurnishType: furnish_type,
        editHouseShare: house_share,
        editStudentAccommodation: student_accommodation,
        editRetirementHome: retirement_home,
        editPetsAllowed: pets_allowed,
        editNewHome: new_home,
      });
    } else {
      setPropertyData({
        ...propertyData,
        editLocation: null,
        editBuyPrice: null,
        editRentPrice: null,
        editRentPriceType: null,
        editBedrooms: null,
        editBathrooms: null,
        editPropertyType: null,
        editLatestBid: null,
        editAgentName: null,
        editAgentEmail: null,
        editAgentContactNumber: null,
        editLikes: null,
        editDislikes: null,
        editNotes: null,
        editGroundRent: null,
        editSize: null,
        editSizeType: null,
        editUrl: null,
        editTenure: null,
        editServiceCharge: null,
        editGarden: false,
        editNeedsRefurbishment: false,
        editParkingSpace: false,
        editViewingDate: null,
        editViewingTime: null,
        editAddedDate: null,
        editLeaseLength: null,
        editLetAvailableDate: null,
        editDeposit: null,
        editLetType: null,
        editFurnishType: null,
        editHouseShare: null,
        editStudentAccommodation: null,
        editRetirementHome: null,
        editPetsAllowed: null,
        editNewHome: null,
      });
    }
  }, [open]);

  useEffect(() => {
    propertyData.editLocation &&
    (propertyData.editBuyPrice ||
      propertyData.editBuyPrice === 0 ||
      propertyData.editRentPrice === 0 ||
      propertyData.editRentPrice)
      ? setDisable(false)
      : setDisable(true);

    if (propertyData.editUrl) {
      if (validator.isURL(propertyData.editUrl)) {
        setUrlErrorMessage('');
      } else if (propertyData.editUrl === '') {
        setUrlErrorMessage('');
        setDisable(false);
      } else {
        setUrlErrorMessage('Please enter a valid url');
        setDisable(true);
      }
    }
  }, [
    propertyData.editLocation,
    propertyData.editBuyPrice,
    propertyData.editRentPrice,
    propertyData.editUrl,
  ]);

  const handleStringTypeDataChange = (e) => {
    const { name, value } = e.target;

    setPropertyData({
      ...propertyData,
      [name]: value.trimStart(),
    });
  };

  const handleNumberTypeDataChange = (e) => {
    const { name, value } = e.target;
    let cleanedValue = null;

    if (value !== '') {
      cleanedValue = value;

      if (value < min) cleanedValue = min;
      if (value > max) cleanedValue = max;
    }

    setPropertyData({
      ...propertyData,
      [name]: cleanedValue,
    });
  };

  const handleUrlDataChange = (e) => {
    const { name, value } = e.target;

    setPropertyData({
      ...propertyData,
      [name]: value,
    });
  };

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setPropertyData({
      ...propertyData,
      [name]: checked,
    });
  };

  const handleViewingDate = (newValue) => {
    const formattedDate = newValue?.format('DD/MM/YYYY');
    if (validator.isDate(formattedDate, { format: 'DD/MM/YYYY' })) {
      setViewingDateErrorMessage('');
    } else if (formattedDate === undefined) {
      setViewingDateErrorMessage('');
    } else {
      setViewingDateErrorMessage('Invalid date');
    }

    setPropertyData({
      ...propertyData,
      editViewingDate: newValue,
    });
  };

  const handleViewingTime = (newValue) => {
    const formattedTime = newValue?.format('hh:mm');
    if (validator.isTime(formattedTime, { format: 'HH:mm' })) {
      setTimeErrorMessage('');
    } else if (formattedTime === undefined) {
      setTimeErrorMessage('');
    } else {
      setTimeErrorMessage('Invalid time');
    }

    setPropertyData({
      ...propertyData,
      editViewingTime: newValue,
    });
  };

  const handleAddedDate = (newValue) => {
    const formattedDate = newValue?.format('DD/MM/YYYY');
    if (validator.isDate(formattedDate, { format: 'DD/MM/YYYY' })) {
      setAddedDateErrorMessage('');
    } else if (formattedDate === undefined) {
      setAddedDateErrorMessage('');
    } else {
      setAddedDateErrorMessage('Invalid date');
    }

    setPropertyData({
      ...propertyData,
      editAddedDate: newValue,
    });
  };

  const handleLetAvailableDate = (newValue) => {
    const formattedDate = newValue?.format('DD/MM/YYYY');
    if (validator.isDate(formattedDate, { format: 'DD/MM/YYYY' })) {
      setLetAvailableDateErrorMessage('');
    } else if (formattedDate === undefined) {
      setLetAvailableDateErrorMessage('');
    } else {
      setLetAvailableDateErrorMessage('Invalid date');
    }

    setPropertyData({
      ...propertyData,
      editLetAvailableDate: newValue,
    });
  };

  const onKeyDownFloatTypeHandler = (e) => {
    if (['e', 'E', '+', '-'].includes(e.key)) {
      e.preventDefault();
    }
  };

  const onKeyDownIntTypeHandler = (e) => {
    if (['e', 'E', '+', '-', '.'].includes(e.key)) {
      e.preventDefault();
    }
  };

  const { getAccessTokenSilently } = useAuth0();
  const devUrl = process.env.REACT_APP_API_URL;

  function handleEditProperty() {
    const propertyId = editingProperty.id;
    const request = {
      user_id: 1,
      location: propertyData.editLocation,
      buy_price: propertyData.editBuyPrice,
      rent_price: propertyData.editRentPrice,
      rent_price_type: propertyData.editRentPriceType,
      bedrooms: propertyData.editBedrooms,
      bathrooms: propertyData.editBathrooms,
      property_type: propertyData.editPropertyType,
      latest_bid: propertyData.editLatestBid,
      agent_name: propertyData.editAgentName,
      agent_email: propertyData.editAgentEmail,
      agent_contact_number: propertyData.editAgentContactNumber,
      likes: propertyData.editLikes,
      dislikes: propertyData.editDislikes,
      notes: propertyData.editNotes,
      ground_rent: propertyData.editGroundRent,
      size: propertyData.editSize,
      size_type: propertyData.editSizeType,
      url: propertyData.editUrl,
      tenure: propertyData.editTenure,
      service_charge: propertyData.editServiceCharge,
      garden: propertyData.editGarden,
      needs_refurbishment: propertyData.editNeedsRefurbishment,
      parking_space: propertyData.editParkingSpace,
      viewing_date: propertyData.editViewingDate?.isValid()
        ? propertyData.editViewingDate.format('DD/MM/YYYY')
        : null,
      viewing_time: propertyData.editViewingTime?.isValid()
        ? propertyData.editViewingTime.format('HH:mm')
        : null,
      added_date: propertyData.editAddedDate?.isValid()
        ? propertyData.editAddedDate.format('DD/MM/YYYY')
        : null,
      lease_length: propertyData.editLeaseLength,
      let_available_date: propertyData.editLetAvailableDate?.isValid()
        ? propertyData.editLetAvailableDate.format('DD/MM/YYYY')
        : null,
      deposit: propertyData.editDeposit,
      let_type: propertyData.editLetType,
      furnish_type: propertyData.editFurnishType,
      house_share: propertyData.editHouseShare,
      student_accommodation: propertyData.editStudentAccommodation,
      retirement_home: propertyData.editRetirementHome,
      pets_allowed: propertyData.editPetsAllowed,
      new_home: propertyData.editNewHome,
    };

    getAccessTokenSilently().then((result) => {
      localStorage.setItem('token', result);
    });

    const axiosConfig = {
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      },
    };

    axios
      .post(`${devUrl}/properties/${propertyId}/`, request, axiosConfig)
      .then((response) => {
        if (response.status === 200) {
          setSuccessAlertContent('Changes saved');
          setSuccessAlert(true);
          setTimeout(() => {
            setSuccessAlert(false);
          }, 2000);
        }

        dispatch(
          editProperty({
            propertyId: propertyId,
            location: propertyData.editLocation,
            buy_price: propertyData.editBuyPrice,
            rent_price: propertyData.editRentPrice,
            rent_price_type: propertyData.editRentPriceType,
            bedrooms: propertyData.editBedrooms,
            bathrooms: propertyData.editBathrooms,
            property_type: propertyData.editPropertyType,
            latest_bid: propertyData.editLatestBid,
            agent_name: propertyData.editAgentName,
            agent_email: propertyData.editAgentEmail,
            agent_contact_number: propertyData.editAgentContactNumber,
            likes: propertyData.editLikes,
            dislikes: propertyData.editDislikes,
            notes: propertyData.editNotes,
            ground_rent: propertyData.editGroundRent,
            size: propertyData.editSize,
            size_type: propertyData.editSizeType,
            url: propertyData.editUrl,
            tenure: propertyData.editTenure,
            service_charge: propertyData.editServiceCharge,
            garden: propertyData.editGarden,
            needs_refurbishment: propertyData.editNeedsRefurbishment,
            parking_space: propertyData.editParkingSpace,
            viewing_date: propertyData.editViewingDate?.isValid()
              ? propertyData.editViewingDate.format('DD/MM/YYYY')
              : null,
            viewing_time: propertyData.editViewingTime?.isValid()
              ? propertyData.editViewingTime.format('HH:mm')
              : null,
            added_date: propertyData.editAddedDate?.isValid()
              ? propertyData.editAddedDate.format('DD/MM/YYYY')
              : null,
            lease_length: propertyData.editLeaseLength,
            let_available_date: propertyData.editLetAvailableDate?.isValid()
              ? propertyData.editLetAvailableDate.format('DD/MM/YYYY')
              : null,
            deposit: propertyData.editDeposit,
            let_type: propertyData.editLetType,
            furnish_type: propertyData.editFurnishType,
            house_share: propertyData.editHouseShare,
            student_accommodation: propertyData.editStudentAccommodation,
            retirement_home: propertyData.editRetirementHome,
            pets_allowed: propertyData.editPetsAllowed,
            new_home: propertyData.editNewHome,
          })
        );
      })
      .catch((error) => {
        setErrorAlertContent('Changes could not be saved');
        setErrorAlert(true);
        setTimeout(() => {
          setErrorAlert(false);
        }, 2000);
      });
  }

  return (
    <Dialog
      open={open}
      className='edit-property-modal-container'
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
      component='form'
      autoComplete='off'
      fullWidth
      PaperProps={{
        sx: {
          maxHeight: '98%',
          maxWidth: '70%',
        },
      }}
      slotProps={{
        backdrop: { style: { backgroundColor: 'rgba(0, 0, 0, 0.5)' } },
      }}
      onClose={() => {
        dispatch(handleCloseEditModal());
        setUrlErrorMessage(false);
        setViewingDateErrorMessage(false);
        setAddedDateErrorMessage(false);
        setTimeErrorMessage(false);
        setLetAvailableDateErrorMessage(false);
      }}
    >
      <Box sx={{ p: 2 }}>
        <Typography className='board-title' variant='h6' component='h2'>
          Property Details
        </Typography>

        <Box className='edit-property-modal' sx={modalContainerStyles}>
          <Box className='left-side' sx={leftSideStyles}>
            <Box className='left-side-long-text'>
              <Typography>Link to website</Typography>
              <TextField
                id='outlined-basic'
                variant='outlined'
                size='small'
                fullWidth
                name='editUrl'
                autoComplete='off'
                value={propertyData.editUrl || ''}
                error={urlErrorMessage ? true : false}
                helperText={propertyData.editUrl === '' ? '' : urlErrorMessage}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>
                      <Tooltip title='Open website'>
                        <Link
                          href={propertyData.editUrl}
                          underline='none'
                          target='_blank'
                          rel='noopener noreferrer'
                        >
                          <IconButton aria-label='link-to-website' edge='end'>
                            <LinkIcon />
                          </IconButton>
                        </Link>
                      </Tooltip>
                    </InputAdornment>
                  ),
                }}
                onChange={handleUrlDataChange}
              />

              <Typography sx={{ mt: 1 }}>Address</Typography>
              <TextField
                id='outlined-basic'
                variant='outlined'
                size='small'
                fullWidth
                name='editLocation'
                autoComplete='off'
                inputProps={{ maxLength: 180 }}
                value={propertyData.editLocation || ''}
                error={propertyData.editLocation ? false : true}
                helperText={
                  propertyData.editLocation ? '' : 'Address field is required'
                }
                onChange={handleStringTypeDataChange}
              />
            </Box>

            <Box className='left-side-short-text' sx={leftSideShortTextStyles}>
              <Box
                className='left-side-short-text-left'
                sx={leftSideShortTextLeftStyles}
              >
                {price_type === 'BUY' && (
                  <Box>
                    <Typography>Buy Price</Typography>
                    <TextField
                      id='outlined-basic'
                      variant='outlined'
                      size='small'
                      fullWidth
                      type='Number'
                      onWheel={(event) => event.target.blur()}
                      autoComplete='off'
                      error={
                        propertyData.editBuyPrice === 0 ||
                        propertyData.editBuyPrice
                          ? false
                          : true
                      }
                      helperText={
                        propertyData.editBuyPrice === 0 ||
                        propertyData.editBuyPrice
                          ? ''
                          : 'Please enter a number'
                      }
                      inputProps={{ min, max }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position='start'>£</InputAdornment>
                        ),
                      }}
                      name='editBuyPrice'
                      value={
                        propertyData.editBuyPrice === 0 ||
                        propertyData.editBuyPrice
                          ? propertyData.editBuyPrice
                          : ''
                      }
                      onKeyDown={onKeyDownFloatTypeHandler}
                      onChange={handleNumberTypeDataChange}
                    />
                  </Box>
                )}

                {price_type === 'RENT' && (
                  <Box>
                    <Typography>Rent Price</Typography>
                    <Box className='rent-price-container'>
                      <TextField
                        id='outlined-basic'
                        className='rent-price'
                        variant='outlined'
                        size='small'
                        fullWidth
                        type='Number'
                        onWheel={(event) => event.target.blur()}
                        autoComplete='off'
                        error={
                          propertyData.editRentPrice === 0 ||
                          propertyData.editRentPrice
                            ? false
                            : true
                        }
                        helperText={
                          propertyData.editRentPrice === 0 ||
                          propertyData.editRentPrice
                            ? ''
                            : 'Please enter a number'
                        }
                        inputProps={{ min, max }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position='start'>£</InputAdornment>
                          ),
                        }}
                        name='editRentPrice'
                        value={
                          propertyData.editRentPrice === 0 ||
                          propertyData.editRentPrice
                            ? propertyData.editRentPrice
                            : ''
                        }
                        onKeyDown={onKeyDownFloatTypeHandler}
                        onChange={handleNumberTypeDataChange}
                      />
                      <TextField
                        id='outlined-basic'
                        variant='outlined'
                        size='small'
                        fullWidth
                        name='editRentPriceType'
                        autoComplete='off'
                        value={propertyData.editRentPriceType || ''}
                        select
                        isclearable='true'
                        onChange={handleStringTypeDataChange}
                      >
                        {rentPriceTypeDropdown.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Box>
                  </Box>
                )}
                <Box className='bed-bath-container'>
                  <Box sx={{ mt: 1, mr: 1, width: '50%' }}>
                    <Typography>Bedrooms</Typography>
                    <TextField
                      id='outlined-basic'
                      variant='outlined'
                      size='small'
                      fullWidth
                      type='Number'
                      onWheel={(event) => event.target.blur()}
                      autoComplete='off'
                      name='editBedrooms'
                      value={propertyData.editBedrooms || ''}
                      onKeyDown={onKeyDownIntTypeHandler}
                      onChange={handleNumberTypeDataChange}
                    />
                  </Box>
                  <Box sx={{ mt: 1, width: '50%' }}>
                    <Typography>Bathrooms</Typography>
                    <TextField
                      id='outlined-basic'
                      variant='outlined'
                      size='small'
                      fullWidth
                      type='Number'
                      onWheel={(event) => event.target.blur()}
                      autoComplete='off'
                      name='editBathrooms'
                      value={propertyData.editBathrooms || ''}
                      onKeyDown={onKeyDownIntTypeHandler}
                      onChange={handleNumberTypeDataChange}
                    />
                  </Box>
                </Box>
                {price_type === 'BUY' && (
                  <Box>
                    <Typography sx={{ mt: 1 }}>Property type</Typography>
                    <TextField
                      id='outlined-basic'
                      variant='outlined'
                      size='small'
                      fullWidth
                      name='editPropertyType'
                      autoComplete='off'
                      value={propertyData.editPropertyType || ''}
                      select
                      isclearable='true'
                      onChange={handleStringTypeDataChange}
                    >
                      {buyPropertyType.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Box>
                )}
                {price_type === 'RENT' && (
                  <Box>
                    <Typography sx={{ mt: 1 }}>Property type</Typography>
                    <TextField
                      id='outlined-basic'
                      variant='outlined'
                      size='small'
                      fullWidth
                      name='editPropertyType'
                      autoComplete='off'
                      value={propertyData.editPropertyType || ''}
                      select
                      isclearable='true'
                      onChange={handleStringTypeDataChange}
                    >
                      {rentPropertyType.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Box>
                )}

                <Typography sx={{ mt: 1 }}>Size</Typography>
                <Box className='size-container'>
                  <TextField
                    id='outlined-basic'
                    className='size'
                    variant='outlined'
                    size='small'
                    fullWidth
                    type='Number'
                    onWheel={(event) => event.target.blur()}
                    autoComplete='off'
                    inputProps={{ min, max }}
                    name='editSize'
                    value={propertyData.editSize || ''}
                    onKeyDown={onKeyDownFloatTypeHandler}
                    onChange={handleNumberTypeDataChange}
                  />
                  <TextField
                    id='outlined-basic'
                    variant='outlined'
                    size='small'
                    fullWidth
                    name='editSizeType'
                    autoComplete='off'
                    value={propertyData.editSizeType || ''}
                    select
                    isclearable='true'
                    onChange={handleStringTypeDataChange}
                  >
                    {SizeType.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </Box>
                <Typography sx={{ mt: 1 }}>Added on market</Typography>
                <DatePicker
                  name='editAddedDate'
                  format='DD/MM/YYYY'
                  autoComplete='off'
                  fullWidth
                  value={propertyData.editAddedDate || null}
                  slotProps={{
                    textField: {
                      size: 'small',
                      error: false,
                      helperText: addedDateErrorMessage,
                      fullWidth: true,
                    },
                  }}
                  onChange={(newValue) => {
                    handleAddedDate(newValue);
                  }}
                />

                {price_type === 'RENT' && (
                  <Box>
                    <Typography sx={{ mt: 1 }}>Deposit</Typography>
                    <TextField
                      id='outlined-basic'
                      variant='outlined'
                      size='small'
                      fullWidth
                      type='Number'
                      onWheel={(event) => event.target.blur()}
                      autoComplete='off'
                      inputProps={{ min, max }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position='start'>£</InputAdornment>
                        ),
                      }}
                      name='editDeposit'
                      value={propertyData.editDeposit || ''}
                      onKeyDown={onKeyDownFloatTypeHandler}
                      onChange={handleNumberTypeDataChange}
                    />
                    <Typography sx={{ mt: 1 }}>Furnish Type</Typography>
                    <TextField
                      id='outlined-basic'
                      variant='outlined'
                      size='small'
                      fullWidth
                      name='editFurnishType'
                      autoComplete='off'
                      value={propertyData.editFurnishType || ''}
                      select
                      isclearable='true'
                      onChange={handleStringTypeDataChange}
                    >
                      {furnishType.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Box>
                )}

                {price_type === 'BUY' && (
                  <Box>
                    <Typography sx={{ mt: 1 }}>Tenure</Typography>
                    <TextField
                      id='outlined-basic'
                      variant='outlined'
                      size='small'
                      fullWidth
                      name='editTenure'
                      autoComplete='off'
                      value={propertyData.editTenure || ''}
                      select
                      isclearable='true'
                      onChange={handleStringTypeDataChange}
                    >
                      {TenureType.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                    <Typography sx={{ mt: 1 }}>Lease Length</Typography>
                    <TextField
                      id='outlined-basic'
                      variant='outlined'
                      size='small'
                      fullWidth
                      type='Number'
                      onWheel={(event) => event.target.blur()}
                      autoComplete='off'
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position='end'>years</InputAdornment>
                        ),
                      }}
                      name='editLeaseLength'
                      value={propertyData.editLeaseLength || ''}
                      onKeyDown={onKeyDownIntTypeHandler}
                      onChange={handleNumberTypeDataChange}
                    />
                    <Typography sx={{ mt: 1 }}>Latest bid</Typography>
                    <TextField
                      id='outlined-basic'
                      variant='outlined'
                      size='small'
                      fullWidth
                      type='Number'
                      onWheel={(event) => event.target.blur()}
                      autoComplete='off'
                      inputProps={{ min, max }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position='start'>£</InputAdornment>
                        ),
                      }}
                      name='editLatestBid'
                      value={propertyData.editLatestBid || ''}
                      onKeyDown={onKeyDownFloatTypeHandler}
                      onChange={handleNumberTypeDataChange}
                    />
                  </Box>
                )}
              </Box>

              <Box
                className='left-side-short-text-right'
                sx={leftSideShortTextRightStyles}
              >
                <Typography>Viewing Date</Typography>
                <DatePicker
                  name='editViewingDate'
                  format='DD/MM/YYYY'
                  autoComplete='off'
                  value={propertyData.editViewingDate || null}
                  slotProps={{
                    textField: {
                      size: 'small',
                      error: false,
                      helperText: viewingDateErrorMessage,
                      fullWidth: true,
                    },
                  }}
                  onChange={(newValue) => {
                    handleViewingDate(newValue);
                  }}
                />

                <Typography sx={{ mt: 1 }}>Viewing Time</Typography>
                <TimePicker
                  name='editViewingTime'
                  autoComplete='off'
                  value={propertyData.editViewingTime || null}
                  slotProps={{
                    textField: {
                      size: 'small',
                      error: false,
                      helperText: timeErrorMessage,
                      fullWidth: true,
                    },
                  }}
                  ampm={false}
                  onChange={(newValue) => {
                    handleViewingTime(newValue);
                  }}
                />
                {price_type === 'RENT' && (
                  <Box>
                    <Typography sx={{ mt: 1 }}>Let Type</Typography>
                    <TextField
                      id='outlined-basic'
                      variant='outlined'
                      size='small'
                      fullWidth
                      name='editLetType'
                      autoComplete='off'
                      value={propertyData.editLetType || ''}
                      select
                      isclearable='true'
                      onChange={handleStringTypeDataChange}
                    >
                      {letType.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                    <Typography sx={{ mt: 1 }}>Let Available Date</Typography>
                    <DatePicker
                      name='editLetAvailableDate'
                      format='DD/MM/YYYY'
                      autoComplete='off'
                      value={propertyData.editLetAvailableDate || null}
                      slotProps={{
                        textField: {
                          size: 'small',
                          error: false,
                          helperText: letAvailableDateErrorMessage,
                          fullWidth: true,
                        },
                      }}
                      onChange={(newValue) => {
                        handleLetAvailableDate(newValue);
                      }}
                    />
                  </Box>
                )}
                {price_type === 'BUY' && (
                  <Box>
                    <Typography sx={{ mt: 1 }}>Ground rent</Typography>
                    <TextField
                      id='outlined-basic'
                      variant='outlined'
                      size='small'
                      fullWidth
                      type='Number'
                      onWheel={(event) => event.target.blur()}
                      autoComplete='off'
                      inputProps={{ min, max }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position='start'>£</InputAdornment>
                        ),
                      }}
                      name='editGroundRent'
                      value={propertyData.editGroundRent || ''}
                      onKeyDown={onKeyDownFloatTypeHandler}
                      onChange={handleNumberTypeDataChange}
                    />

                    <Typography sx={{ mt: 1 }}>Service charge</Typography>
                    <TextField
                      id='outlined-basic'
                      variant='outlined'
                      size='small'
                      type='Number'
                      onWheel={(event) => event.target.blur()}
                      fullWidth
                      autoComplete='off'
                      inputProps={{ min, max }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position='start'>£</InputAdornment>
                        ),
                      }}
                      name='editServiceCharge'
                      value={propertyData.editServiceCharge || ''}
                      onKeyDown={onKeyDownFloatTypeHandler}
                      onChange={handleNumberTypeDataChange}
                    />
                  </Box>
                )}

                <Typography sx={{ mt: 1 }}>Agent Name</Typography>
                <TextField
                  id='outlined-basic'
                  variant='outlined'
                  size='small'
                  fullWidth
                  autoComplete='off'
                  name='editAgentName'
                  inputProps={{ maxLength: 19 }}
                  value={propertyData.editAgentName || ''}
                  onChange={handleStringTypeDataChange}
                />
                <Typography sx={{ mt: 1 }}>Agent Number</Typography>
                <TextField
                  id='outlined-basic'
                  variant='outlined'
                  size='small'
                  fullWidth
                  autoComplete='off'
                  name='editAgentContactNumber'
                  inputProps={{ maxLength: 180 }}
                  value={propertyData.editAgentContactNumber || ''}
                  onChange={handleStringTypeDataChange}
                />
                <Typography sx={{ mt: 1 }}>Agent Email</Typography>
                <TextField
                  id='outlined-basic'
                  variant='outlined'
                  size='small'
                  fullWidth
                  autoComplete='off'
                  name='editAgentEmail'
                  inputProps={{ maxLength: 180 }}
                  value={propertyData.editAgentEmail || ''}
                  onChange={handleStringTypeDataChange}
                />
              </Box>
            </Box>
            <Box className='checkbox-container' sx={checkboxContainerStyles}>
              <Box sx={checkboxContainerLeftStyles}>
                <Box className='checkbox' sx={checkboxStyles}>
                  <Typography>Garden</Typography>
                  <Checkbox
                    name='editGarden'
                    checked={propertyData.editGarden || false}
                    onChange={handleCheckboxChange}
                  />
                </Box>
                {price_type === 'RENT' && (
                  <Box className='checkbox' sx={checkboxStyles}>
                    <Typography>House Share</Typography>
                    <Checkbox
                      name='editHouseShare'
                      checked={propertyData.editHouseShare || false}
                      onChange={handleCheckboxChange}
                    />
                  </Box>
                )}
                <Box className='checkbox' sx={checkboxStyles}>
                  <Typography>Parking space</Typography>
                  <Checkbox
                    name='editParkingSpace'
                    checked={propertyData.editParkingSpace || false}
                    onChange={handleCheckboxChange}
                  />
                </Box>
                {price_type === 'RENT' && (
                  <Box className='checkbox' sx={checkboxStyles}>
                    <Typography>Student Accommodation</Typography>
                    <Checkbox
                      name='editStudentAccommodation'
                      checked={propertyData.editStudentAccommodation || false}
                      onChange={handleCheckboxChange}
                    />
                  </Box>
                )}
                <Box>
                  {price_type === 'BUY' && (
                    <Box className='checkbox' sx={checkboxStyles}>
                      <Typography>New Home</Typography>
                      <Checkbox
                        name='editNewHome'
                        checked={propertyData.editNewHome || false}
                        onChange={handleCheckboxChange}
                      />
                    </Box>
                  )}
                </Box>
              </Box>

              <Box>
                <Box className='checkbox' sx={checkboxStyles}>
                  <Typography>Newly refurbished</Typography>
                  <Checkbox
                    name='editNeedsRefurbishment'
                    checked={propertyData.editNeedsRefurbishment || false}
                    onChange={handleCheckboxChange}
                  />
                </Box>
                <Box className='checkbox' sx={checkboxStyles}>
                  <Typography>Retirement Home</Typography>
                  <Checkbox
                    name='editRetirementHome'
                    checked={propertyData.editRetirementHome || false}
                    onChange={handleCheckboxChange}
                  />
                </Box>

                <Box>
                  {price_type === 'RENT' && (
                    <Box className='checkbox' sx={checkboxStyles}>
                      <Typography>Pets allowed</Typography>
                      <Checkbox
                        name='editPetsAllowed'
                        checked={propertyData.editPetsAllowed || false}
                        onChange={handleCheckboxChange}
                      />
                    </Box>
                  )}
                </Box>
              </Box>
            </Box>
          </Box>

          <Box className='right-side' sx={rightSideStyles}>
            <Typography>Likes</Typography>
            <TextField
              id='outlined-multiline-static'
              multiline
              fullWidth
              rows={6}
              name='editLikes'
              autoComplete='off'
              inputProps={{ maxLength: 1999 }}
              value={propertyData.editLikes || ''}
              onChange={handleStringTypeDataChange}
            />
            <Typography sx={{ mt: 1 }}>Dislikes</Typography>
            <TextField
              id='outlined-multiline-static'
              multiline
              fullWidth
              rows={6}
              name='editDislikes'
              autoComplete='off'
              inputProps={{ maxLength: 1999 }}
              value={propertyData.editDislikes || ''}
              onChange={handleStringTypeDataChange}
            />
            <Typography sx={{ mt: 1 }}>Notes</Typography>
            <TextField
              id='outlined-multiline-static'
              multiline
              fullWidth
              rows={16}
              name='editNotes'
              autoComplete='off'
              inputProps={{ maxLength: 1999 }}
              value={propertyData.editNotes || ''}
              onChange={handleStringTypeDataChange}
            />
          </Box>
        </Box>

        <Box className='alert-container'>
          {successAlert && (
            <Alert severity='success'>{successAlertContent}</Alert>
          )}
          {errorAlert && <Alert severity='error'>{errorAlertContent}</Alert>}
        </Box>
        <Box className='button-container'>
          <Button
            className='cancel-save-property-button'
            variant='outlined'
            sx={{ mr: 1 }}
            onClick={() => {
              dispatch(handleCloseEditModal());
              setUrlErrorMessage(false);
              setViewingDateErrorMessage(false);
              setAddedDateErrorMessage(false);
              setTimeErrorMessage(false);
              setLetAvailableDateErrorMessage(false);
            }}
          >
            Cancel
          </Button>
          <Button
            className='save-property-button'
            variant='contained'
            disabled={disable}
            onClick={handleEditProperty}
          >
            Save changes
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
}
