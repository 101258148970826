export const servicesContainerStyles = (theme) => ({
  [theme.breakpoints.up('xs')]: {
    height: '86vh',
    margin: '4vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
});

export const servicesTopStyles = (theme) => ({
  [theme.breakpoints.up('xs')]: {
    height: '72vh',
  },
  [theme.breakpoints.up('sm')]: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
  },
  [theme.breakpoints.up('lg')]: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
});

export const servicesTextStyles = (theme) => ({
  [theme.breakpoints.up('xs')]: {
    textAlign: 'center',
  },

  [theme.breakpoints.up('lg')]: {
    textAlign: 'left',
    width: '20%',
  },
});

export const servicesSliderStyles = (theme) => ({
  [theme.breakpoints.up('xs')]: {
    height: '40vh',
  },
  [theme.breakpoints.up('sm')]: {
    height: '50vh',
  },
  [theme.breakpoints.up('md')]: {
    height: '60vh',
  },
  [theme.breakpoints.up('lg')]: {
    width: '60%',
    height: '70vh',
  },
});

export const buttonContainerStyles = (theme) => ({
  [theme.breakpoints.up('xs')]: {
    height: '4vh',
  },
});

export const buttonStyles = (theme) => ({
  [theme.breakpoints.up('xs')]: {
    textTransform: 'capitalize',
    fontSize: 12,
  },
  [theme.breakpoints.up('sm')]: {
    fontSize: 14,
  },
  [theme.breakpoints.up('md')]: {
    fontSize: 16,
  },
  [theme.breakpoints.up('lg')]: {
    fontSize: 18,
  },
  [theme.breakpoints.up('xl')]: {
    fontSize: 20,
  },
});
