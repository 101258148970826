import { Alert, Box } from '@mui/material';
import { useSelector } from 'react-redux';

export default function AddPropertyAlertBanner({ columnId }) {
  const columnForNewProperty = useSelector(
    (state) => state.properties.columnForNewProperty
  );

  return (
    <Box style={{ margin: '0 16px' }}>
      {columnId === columnForNewProperty && (
        <Alert severity='success'>Property added!</Alert>
      )}
    </Box>
  );
}
