import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import CardItem from '../CardItem/CardItem';
import { Box } from '@mui/material';

export default function SortableItem({ singleProperty, parentColumnId }) {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id: singleProperty.id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    opacity: isDragging ? 0.5 : 1,
  };

  return (
    <Box ref={setNodeRef} style={style} {...attributes} {...listeners}>
      <CardItem
        id={`property${singleProperty.id}`}
        sortedProperty={singleProperty}
        columnId={parentColumnId}
      />
    </Box>
  );
}
