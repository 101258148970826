import { Box, Button, Typography } from '@mui/material';
import ServicesSlider from '../ServicesSlider/ServicesSlider';
import './Services.scss';
import { useAuth0 } from '@auth0/auth0-react';
import { Link } from 'react-router-dom';
import {
  buttonContainerStyles,
  buttonStyles,
  servicesContainerStyles,
  servicesSliderStyles,
  servicesTextStyles,
  servicesTopStyles,
} from './ServicesMediaQuery';

export default function Services() {
  const { isAuthenticated, loginWithRedirect } = useAuth0();
  const localUrl = process.env.REACT_APP_URL;

  return (
    <Box className='services-container' sx={servicesContainerStyles}>
      <Box className='services-top' sx={servicesTopStyles}>
        <Box className='services-text' sx={servicesTextStyles}>
          <Typography variant='h5'>You can create a property board</Typography>
          <Typography variant='body1' sx={{ mb: 1 }}>
            A property board is designed to keep and track all the information
            that you need about a property.
          </Typography>
          <Typography variant='body1' sx={{ mb: 1 }}>
            Drag and drop your property cards into relevant columns.
          </Typography>
          <Typography variant='body1'>
            Keep customised property details for buying or renting.
          </Typography>
        </Box>
        <Box className='services-slider' sx={servicesSliderStyles}>
          <ServicesSlider />
        </Box>
      </Box>
      <Box className='services-button-container' sx={buttonContainerStyles}>
        {!isAuthenticated ? (
          <Button
            className='services-button'
            sx={buttonStyles}
            variant='contained'
            onClick={() =>
              loginWithRedirect({
                authorizationParams: {
                  redirect_uri: `${localUrl}/board`,
                },
              })
            }
          >
            Get Habitunt - It's Free!
          </Button>
        ) : (
          <Link
            to={`/board`}
            style={{
              textDecoration: 'none',
              color: 'inherit',
            }}
          >
            <Button
              className='services-button'
              sx={buttonStyles}
              variant='contained'
            >
              Go to your board
            </Button>
          </Link>
        )}
      </Box>
    </Box>
  );
}
