import { IconButton, Tooltip } from '@mui/material';
import { useDispatch, useSelector, useStore } from 'react-redux';
import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';
import { addNewColumn, setMaxColumnWarning } from '../../store/properties';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

export default function AddColumnButton() {
  const boardId = useSelector((state) => state.properties.boardMetadata.id);
  const columns = useSelector((state) => state.properties.columns);
  const store = useStore();

  const dispatch = useDispatch();
  const { getAccessTokenSilently } = useAuth0();
  const devUrl = process.env.REACT_APP_API_URL;

  function addColumn() {
    const request = {
      property_board_id: boardId,
      name: `Column Title`,
      position: Object.keys(columns).length + 1,
    };

    getAccessTokenSilently().then((result) => {
      localStorage.setItem('token', result);
    });

    const axiosConfig = {
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      },
    };

    axios
      .post(`${devUrl}/properties/column/`, request, axiosConfig)
      .then((response) => {
        response = response.data;
        dispatch(addNewColumn({ column: response.column }));

        const updatedState = store.getState();
        const totalColumns = Object.entries(
          updatedState.properties.columns
        ).length;
        if (totalColumns === 6) {
          dispatch(setMaxColumnWarning());
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  return (
    <Tooltip title='Add a new column'>
      <IconButton
        style={{
          borderRadius: 10,
          height: '40px',
          width: '40px',
          marginRight: '16px',
        }}
        aria-label='add a column'
        onClick={addColumn}
      >
        <AddCircleOutlineIcon size='large' style={{ color: '#b3d9ff' }} />
      </IconButton>
    </Tooltip>
  );
}
