import { Alert, Box } from '@mui/material';
import { useDispatch } from 'react-redux';
import { unsetMaxPropertyWarning } from '../../store/properties';
import './MaxPropertyBanner.scss';

export default function MaxPropertyBanner() {
  const dispatch = useDispatch();

  return (
    <Box>
      <Alert
        className='property-banner-container'
        severity='warning'
        onClose={() => {
          dispatch(unsetMaxPropertyWarning());
        }}
      >
        Maximum property number reached.
      </Alert>
    </Box>
  );
}
