import { Box, List, ListItem, ListItemText, Typography } from '@mui/material';
import NavBar from '../NavBar/NavBar';
import {
  aboutContainerStyles,
  aboutTextStyles,
  aboutTitleStyles,
} from './AboutPageMediaQuery';
import './AboutPage.scss';
import { brandColors } from '../../utils/brandColors';
import { useEffect } from 'react';

export default function AboutPage() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Box className='about-page'>
      <NavBar />
      <Box className='about-container' sx={aboutContainerStyles}>
        <Box>
          <Typography
            variant='h4'
            className='main-title'
            textAlign='center'
            sx={aboutTitleStyles}
          >
            About Habitunt
          </Typography>
          <Typography variant='body1' textAlign='center' sx={aboutTextStyles}>
            Habitunt is a productivity tool. It helps you with organising your
            search for your next home. It will save you time and help you with
            your decision making.
          </Typography>
        </Box>
        <Box>
          <Typography
            variant='h5'
            sx={{
              mt: 2,
              textDecoration: 'underline',
              textDecorationColor: `${brandColors.purple}`,
              textAlign: 'center',
            }}
          >
            How does it work?
          </Typography>
          <List>
            <ListItem>
              <ListItemText>
                <Typography variant='body1' textAlign='center'>
                  You can create a property board where you can add properties
                  as cards.
                </Typography>
              </ListItemText>
            </ListItem>
            <ListItem>
              <ListItemText>
                <Typography variant='body1' textAlign='center'>
                  These cards can be dragged and dropped to relevant columns
                  showing where you are with your search.
                </Typography>
              </ListItemText>
            </ListItem>

            <ListItem>
              <ListItemText>
                <Typography variant='body1' textAlign='center'>
                  You can rename your board title and columns as you wish.
                </Typography>
              </ListItemText>
            </ListItem>
            <ListItem>
              <ListItemText>
                <Typography variant='body1' textAlign='center'>
                  More information about properties can be added to these cards
                  with different sections for property details for renting and
                  buying.
                </Typography>
              </ListItemText>
            </ListItem>
          </List>
        </Box>
      </Box>
    </Box>
  );
}
