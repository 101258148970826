import { Box, Typography } from '@mui/material';
import NavBar from '../NavBar/NavBar';
import './TermsAndConditions.scss';
import { useEffect } from 'react';

export default function TermsAndConditions() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Box>
      <NavBar />
      <Box className="terms-container">
        <Typography variant="h4" className="main-title">
          TERMS AND CONDITIONS
        </Typography>
        <Typography variant="body1" className="bold-text">
          Please read all these terms and conditions.
        </Typography>
        <Typography variant="body1">
          As we can accept your order and make a legally enforceable agreement
          without further reference to you, you must read these terms and
          conditions to make sure that they contain all that you want and
          nothing that you are not happy with.
        </Typography>
        <br />
        <Typography variant="h6" className="sub-title">
          Application
        </Typography>
        <Box className="numbered-list">
          <Typography variant="body1">
            1. These Terms and Conditions will apply to the purchase of the
            services and goods by you (the{' '}
            <span className="bold-text">Customer</span> or{' '}
            <span className="bold-text">you</span>). We are Habitunt with email
            address info@habitunt.com; (the{' '}
            <span className="bold-text">Supplier</span> or{' '}
            <span className="bold-text">us</span> or{' '}
            <span className="bold-text">we</span>).
          </Typography>
          <Typography variant="body1">
            2. These are the terms on which we sell all Services to you. By
            signing up to any of the Services on Habitunt, you agree to be bound
            by these Terms and Conditions. You can only use the Services from
            the Website if you are eligible to enter into a contract and are at
            least 18 years old.
          </Typography>
        </Box>
        <br />
        <Typography variant="h6" className="sub-title">
          Interpretation
        </Typography>
        <Box className="numbered-list">
          <Typography variant="body1">
            3. <span className="bold-text">Consumer</span> means an individual
            acting for purposes which are wholly or mainly outside their trade,
            business, craft or profession;
          </Typography>
          <Typography variant="body1">
            4. <span className="bold-text">Contract</span> means the
            legally-binding agreement between you and us for the supply of the
            Services;
          </Typography>
          <Typography variant="body1">
            5. <span className="bold-text">Delivery Location</span> means the
            Supplier's premises or other location where the Services are to be
            supplied, as set out in the Order;
          </Typography>
          <Typography variant="body1">
            6. <span className="bold-text">Durable Medium</span> means paper or
            email, or any other medium that allows information to be addressed
            personally to the recipient, enables the recipient to store the
            information in a way accessible for future reference for a period
            that is long enough for the purposes of the information, and allows
            the unchanged reproduction of the information stored;
          </Typography>
          <Typography variant="body1">
            7. <span className="bold-text">Goods</span> means any goods that we
            supply to you with the Services, of the number and description as
            set out in the Order;
          </Typography>
          <Typography variant="body1">
            8. <span className="bold-text">Order</span> means the Customer's
            order for the Services from the Supplier as submitted following the
            step by step process set out on the Website;
          </Typography>
          <Typography variant="body1">
            9. <span className="bold-text">Privacy Policy</span> means the terms
            which set out how we will deal with confidential and personal
            information received from you via the Website;
          </Typography>
          <Typography variant="body1">
            10. <span className="bold-text">Services</span> means the services
            advertised on the Website, including any Goods, of the number and
            description set out in the Order;
          </Typography>
          <Typography variant="body1">
            11. <span className="bold-text">Website</span> means our website
            www.habitunt.com on which the Services are advertised.
          </Typography>
        </Box>
        <br />
        <Typography variant="h6" className="sub-title">
          Services
        </Typography>
        <Box className="numbered-list">
          <Typography variant="body1">
            12. The description of the Services and any Goods is as set out in
            the Website, catalogues, brochures or other form of advertisement.
            Any description is for illustrative purposes only and there may be
            small discrepancies in the size and colour of any Goods supplied.
          </Typography>
          <Typography variant="body1">
            13. In the case of Services and any Goods made to your special
            requirements, it is your responsibility to ensure that any
            information or specification you provide is accurate.
          </Typography>
          <Typography variant="body1">
            14. All Services which appear on the Website are subject to
            availability.
          </Typography>
          <Typography variant="body1">
            15. We can make changes to the Services which are necessary to
            comply with any applicable law or safety requirement. We will notify
            you of these changes.
          </Typography>
        </Box>
        <br />
        <Typography variant="h6" className="sub-title">
          Customer responsibilities
        </Typography>
        <Box className="numbered-list">
          <Typography variant="body1">
            16. You must co-operate with us in all matters relating to the
            Services, provide us and our authorised employees and
            representatives with access to any premises under your control as
            required, provide us with all information required to perform the
            Services and obtain any necessary licences and consents (unless
            otherwise agreed).
          </Typography>
          <Typography variant="body1">
            17. Failure to comply with the above is a Customer default which
            entitles us to suspend performance of the Services until you remedy
            it or if you fail to remedy it following our request, we can
            terminate the Contract with immediate effect on written notice to
            you.
          </Typography>
        </Box>
        <br />
        <Typography variant="h6" className="sub-title">
          Personal information and Registration
        </Typography>
        <Box className="numbered-list">
          <Typography variant="body1">
            18. When registering to use the Website you must set up an email
            address and password. You remain responsible for all actions taken
            under the chosen email address and password and undertake not to
            disclose your email address and password to anyone else and keep
            them secret.
          </Typography>
          <Typography variant="body1">
            19. We retain and use all information strictly under the Privacy
            Policy.
          </Typography>
          <Typography variant="body1">
            20. We may contact you by using e-mail or other electronic
            communication methods and you expressly agree to this.
          </Typography>
        </Box>
        <br />
        <Typography variant="h6" className="sub-title">
          Duration, termination and suspension
        </Typography>
        <Box className="numbered-list">
          <Typography variant="body1">
            21. The Contract continues as long as it takes us to perform the
            Services.
          </Typography>
          <Typography variant="body1">
            22. Either you or we may terminate the Contract or suspend the
            Services at any time by a written notice of termination or
            suspension to the other if that other:
          </Typography>
          <Box className="numbered-sub-list">
            <Typography variant="body1">
              a. commits a serious breach, or series of breaches resulting in a
              serious breach, of the Contract and the breach either cannot be
              fixed or is not fixed within 30 days of the written notice; or
            </Typography>
            <Typography variant="body1">
              b. is subject to any step towards its bankruptcy or liquidation.
            </Typography>
          </Box>
          <Typography variant="body1">
            23. On termination of the Contract for any reason, any of our
            respective remaining rights and liabilities will not be affected.
          </Typography>
        </Box>
        <br />
        <Typography variant="h6" className="sub-title">
          Successors and our sub-contractors
        </Typography>
        <Box className="numbered-list">
          <Typography variant="body1">
            24. Either party can transfer the benefit of this Contract to
            someone else, and will remain liable to the other for its
            obligations under the Contract. The Supplier will be liable for the
            acts of any sub-contractors who it chooses to help perform its
            duties.
          </Typography>
        </Box>
        <br />
        <Typography variant="h6" className="sub-title">
          Circumstances beyond the control of either party
        </Typography>
        <Box className="numbered-list">
          <Typography variant="body1">
            25. In the event of any failure by a party because of something
            beyond its reasonable control:
          </Typography>
          <Box className="numbered-sub-list">
            <Typography variant="body1">
              a. the party will advise the other party as soon as reasonably
              practicable; and
            </Typography>
            <Typography variant="body1">
              b. the party's obligations will be suspended so far as is
              reasonable, provided that that party will act reasonably, and the
              party will not be liable for any failure which it could not
              reasonably avoid, but this will not affect the Customer's above
              rights relating to delivery (and the right to cancel below).
            </Typography>
          </Box>
        </Box>
        <br />
        <Typography variant="h6" className="sub-title">
          Privacy
        </Typography>
        <Box className="numbered-list">
          <Typography variant="body1">
            26. Your privacy is critical to us. We respect your privacy and
            comply with the General Data Protection Regulation with regard to
            your personal information.
          </Typography>
          <Typography variant="body1">
            27. These Terms and Conditions should be read alongside, and are in
            addition to our policies, including our privacy policy
            (www.habitunt.com/privacy-policy) and cookies policy
            (www.habitunt.com).
          </Typography>
          <Typography variant="body1">
            28. For the purposes of these Terms and Conditions:
          </Typography>
          <Box className="numbered-sub-list">
            <Typography variant="body1">
              a. 'Data Protection Laws' means any applicable law relating to the
              processing of Personal Data, including, but not limited to the
              GDPR.
            </Typography>
            <Typography variant="body1">
              b. 'GDPR' means the UK General Data Protection Regulation.
            </Typography>
            <Typography variant="body1">
              c. 'Data Controller', 'Personal Data' and 'Processing' shall have
              the same meaning as in the GDPR.
            </Typography>
          </Box>
          <Typography variant="body1">
            29. For the purposes of these Terms and Conditions:
          </Typography>
          <Typography variant="body1">
            30. Where you supply Personal Data to us so we can provide Services
            and Goods to you, and we Process that Personal Data in the course of
            providing the Services and Goods to you, we will comply with our
            obligations imposed by the Data Protection Laws:
          </Typography>
          <Box className="numbered-sub-list">
            <Typography variant="body1">
              a. before or at the time of collecting Personal Data, we will
              identify the purposes for which information is being collected;
            </Typography>
            <Typography variant="body1">
              b. we will only Process Personal Data for the purposes identified;
            </Typography>
            <Typography variant="body1">
              c. we will respect your rights in relation to your Personal Data;
              and
            </Typography>
            <Typography variant="body1">
              d. we will implement technical and organisational measures to
              ensure your Personal Data is secure.
            </Typography>
          </Box>
          <Typography variant="body1">
            31. For any enquiries or complaints regarding data privacy, you can
            e-mail: info@habitunt.com.
          </Typography>
        </Box>
        <br />
        <Typography variant="h6" className="sub-title">
          Excluding liability
        </Typography>
        <Box className="numbered-list">
          <Typography variant="body1">
            32. The Supplier does not exclude liability for: (i) any fraudulent
            act or omission; or (ii) death or personal injury caused by
            negligence or breach of the Supplier's other legal obligations.
            Subject to this, we are not liable for (i) loss which was not
            reasonably foreseeable to both parties at the time when the Contract
            was made, or (ii) loss (eg loss of profit) to your business, trade,
            craft or profession which would not be suffered by a Consumer -
            because we believe you are not buying the Services and Goods wholly
            or mainly for your business, trade, craft or profession.
          </Typography>
        </Box>
        <br />
        <Typography variant="h6" className="sub-title">
          Governing law, jurisdiction and complaints
        </Typography>
        <Box className="numbered-list">
          <Typography variant="body1">
            33. The Contract (including any non-contractual matters) is governed
            by the law of England and Wales.
          </Typography>
          <Typography variant="body1">
            34. Disputes can be submitted to the jurisdiction of the courts of
            England and Wales or, where the Customer lives in Scotland or
            Northern Ireland, in the courts of respectively Scotland or Northern
            Ireland.
          </Typography>
          <Typography variant="body1">
            35. We try to avoid any dispute, so we deal with complaints as
            follows: If a dispute occurs customers should contact us to find a
            solution. We will aim to respond with an appropriate solution within
            10 working days.
          </Typography>
        </Box>
        <br />
        <Typography variant="h6" className="sub-title">
          Attribution
        </Typography>
        <Box className="numbered-list">
          <Typography variant="body1">
            42. These terms and conditions were created using a document from
            Rocket Lawyer (https://www.rocketlawyer.com/gb/en).
          </Typography>
        </Box>
        <br />
        <Typography variant="body1">
          These terms and conditions were created on{' '}
          <span className="bold-text">27 July 2023</span>.
        </Typography>
      </Box>
    </Box>
  );
}
