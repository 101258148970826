export const MenuIconStyles = (theme) => ({
  [theme.breakpoints.up('md')]: {
    display: 'none',
  },
  [theme.breakpoints.up('lg')]: {
    display: 'none',
  },
  [theme.breakpoints.up('xl')]: {
    display: 'none',
  },
});

export const NavButtonStyles = (theme) => ({
  [theme.breakpoints.up('xs')]: {
    display: 'none',
    textTransform: 'capitalize',
  },
  [theme.breakpoints.up('sm')]: {
    display: 'none',
  },
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
  [theme.breakpoints.up('lg')]: {
    display: 'flex',
  },
  [theme.breakpoints.up('xl')]: {
    display: 'flex',
  },
});

export const NavTitleButtonStyles = (theme) => ({
  [theme.breakpoints.up('xs')]: {
    display: 'none',
    textTransform: 'capitalize',
  },
  [theme.breakpoints.up('sm')]: {
    display: 'none',
  },
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
  [theme.breakpoints.up('lg')]: {
    display: 'flex',
  },
  [theme.breakpoints.up('xl')]: {
    display: 'flex',
  },
});
