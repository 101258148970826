import { SortableContext, rectSortingStrategy } from '@dnd-kit/sortable';
import { useDroppable } from '@dnd-kit/core';
import SortableItem from '../SortableItem/SortableItem';
import './Column.scss';
import { Box } from '@mui/material';
import AddPropertyButton from '../AddPropertyButton/AddPropertyButton';

import ColumnHeader from '../ColumnHeader/ColumnHeader';
import AddPropertyAlertBanner from '../AddPropertyAlertBanner/AddPropertyAlertBanner';
import { useSelector } from 'react-redux';

export default function Column({ id, properties, title }) {
  const { setNodeRef } = useDroppable({ id });
  const totalProperties = useSelector(
    (state) => state.properties.totalProperties
  );

  return (
    <SortableContext id={id} items={properties} strategy={rectSortingStrategy}>
      <Box className='column-container' ref={setNodeRef}>
        <Box className='column-header'>
          <ColumnHeader id={id} name={title} />
          {totalProperties < 10 && <AddPropertyButton id={id} />}
        </Box>
        <AddPropertyAlertBanner columnId={id} />
        <Box className='card-container'>
          {properties.map((singleProperty) => (
            <SortableItem
              id={singleProperty.id}
              key={singleProperty.id}
              singleProperty={singleProperty}
              parentColumnId={id}
            />
          ))}
        </Box>
      </Box>
    </SortableContext>
  );
}
