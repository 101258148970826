import { useAuth0 } from '@auth0/auth0-react';
import { Box, Button, Collapse, Tooltip, Typography } from '@mui/material';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { saveBoardMetadata } from '../../store/properties';
import { Link } from 'react-router-dom';
import './SideBar.scss';
import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { brandColors } from '../../utils/brandColors';
import habituntLogo from '../../assets/habitunt-logo.png';

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(270deg)' : 'rotate(90deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

export default function SideBar() {
  const { isAuthenticated, logout } = useAuth0();
  const boardMetadata = useSelector((state) => state.properties.boardMetadata);
  let { title } = boardMetadata || {};
  const { getAccessTokenSilently } = useAuth0();
  const dispatch = useDispatch();

  const expandMore = 'expandMoreOpen';
  const defaultOpen = localStorage.getItem(expandMore) === 'true';
  const [expanded, setExpanded] = useState(defaultOpen);
  const devUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    localStorage.setItem(expandMore, expanded);
  }, [expanded]);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  useEffect(() => {
    if (!isAuthenticated) {
      return;
    }
    getAccessTokenSilently().then((result) => {
      localStorage.setItem('token', result);
    });

    const axiosConfig = {
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      },
    };

    axios
      .get(`${devUrl}/board/`, axiosConfig)
      .then((response) => {
        const data = response.data;
        dispatch(saveBoardMetadata({ boardMetadata: data[0] }));
      })
      .catch((error) => {
        console.log(error);
      });
  }, [dispatch]);

  const logoutWithRedirect = () =>
    logout({
      logoutParams: {
        returnTo: window.location.origin,
      },
    });

  return (
    <Box className='sidebar-container'>
      <Box className='sidebar-left'>
        <Collapse in={expanded} timeout='auto' unmountOnExit>
          <Box className='collapse-wrapper'>
            <Box className='sidebar-top'>
              <Box>
                <Link to={`/`} className='sidebar-link'>
                  <img
                    src={habituntLogo}
                    alt='habitunt-logo'
                    className='sidebar-logo'
                  />
                </Link>
              </Box>
              {isAuthenticated && (
                <Box>
                  <Tooltip title={title}>
                    <Link to={`/board`} className='sidebar-link'>
                      <Button color='inherit' className='board-title-button'>
                        <Typography className='board-title-text'>
                          {title}
                        </Typography>
                      </Button>
                    </Link>
                  </Tooltip>
                </Box>
              )}
            </Box>

            <Box className='sidebar-bottom'>
              {isAuthenticated && (
                <Box>
                  <Link to={`/account`} className='sidebar-link'>
                    <Button color='inherit' className='sidebar-button'>
                      <Typography noWrap={true}>Account</Typography>
                    </Button>
                  </Link>
                </Box>
              )}
              {isAuthenticated && (
                <Box>
                  <Button
                    color='inherit'
                    className='sidebar-button'
                    onClick={() => logoutWithRedirect()}
                  >
                    <Typography noWrap={true}>Log out</Typography>
                  </Button>
                </Box>
              )}
            </Box>
          </Box>
        </Collapse>
      </Box>

      <Box className='sidebar-right'>
        <ExpandMore
          expand={expanded}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label='show more'
        >
          <ExpandMoreIcon style={{ color: brandColors.purple }} />
        </ExpandMore>
      </Box>
    </Box>
  );
}
