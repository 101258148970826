import { Box, IconButton, Tooltip } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';

import {
  handleOpenEditModal,
  setEditingProperty,
} from '../../store/properties';
import { useDispatch } from 'react-redux';

export default function EditPropertyButton({ columnId, propertyId }) {
  const dispatch = useDispatch();
  return (
    <Box>
      <Tooltip title='Property details'>
        <IconButton
          aria-label='settings'
          onClick={() => {
            dispatch(
              setEditingProperty({ columnId: columnId, propertyId: propertyId })
            );
            dispatch(handleOpenEditModal());
          }}
        >
          <EditIcon />
        </IconButton>
      </Tooltip>
    </Box>
  );
}
