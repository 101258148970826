import { Box, Typography } from '@mui/material';
import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';
import Loading from '../../components/Loading/Loading';
import SideBar from '../SideBar/SideBar';

export default function UserProfile() {
  const { user } = useAuth0();

  return (
    <Box style={{ display: 'flex' }}>
      <SideBar />
      <Box sx={{ m: 2 }}>
        <Typography variant='h6'>Email</Typography>
        <Typography variant='body1'>{user.email}</Typography>
      </Box>
    </Box>
  );
}

withAuthenticationRequired(UserProfile, {
  onRedirecting: () => <Loading />,
});
