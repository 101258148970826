import { IconButton, Typography } from '@mui/material';
import { useDispatch } from 'react-redux';
import { handleOpenAddPropertyModal } from '../../store/properties';
import AddIcon from '@mui/icons-material/Add';
import { brandColors } from '../../utils/brandColors';

export default function AddPropertyButton({ id }) {
  const dispatch = useDispatch();

  return (
    <IconButton
      style={{
        borderRadius: 10,
      }}
      aria-label='add a property'
      onClick={() => {
        dispatch(handleOpenAddPropertyModal({ columnId: id }));
      }}
    >
      <AddIcon
        style={{
          color: brandColors.purple,
          marginRight: 4,
        }}
      />
      <Typography>Add a property</Typography>
    </IconButton>
  );
}
