import { Box, TextField, Typography } from '@mui/material';
import ColumnMenuDropdown from '../ColumnMenuDropdown/ColumnMenuDropdown';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { useAuth0 } from '@auth0/auth0-react';
import { useSelector } from 'react-redux';

export default function ColumnHeader({ id }) {
  const [columnData, setColumnData] = useState({});
  const [isColumnTitleFocused, setIsColumnTitleFocused] = useState(false);

  const { getAccessTokenSilently } = useAuth0();
  const columnMetaData = useSelector((state) => state.properties.columns);
  let { title } = columnMetaData[id] || {};
  const devUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    setColumnData({
      ...columnData,
      editColumnTitle: title,
    });
  }, [title]);

  function handleBlurChange() {
    if (columnData.editColumnTitle === '') {
      setIsColumnTitleFocused(false);
      columnData.editColumnTitle = title;
    } else {
      handleEditColumnTitle();
      setIsColumnTitleFocused(false);
    }
  }

  const handleColumnTitleChange = (e) => {
    const { name, value } = e.target;
    setColumnData({
      ...columnData,
      [name]: value.trimStart(),
    });
  };

  function handleKeyChange(e) {
    if (e.keyCode === 13) {
      e.target.blur();
    }
  }

  function handleEditColumnTitleDropdownClick() {
    setIsColumnTitleFocused(true);
  }

  function handleEditColumnTitle() {
    const columnId = id;

    const request = {
      name: columnData.editColumnTitle.trim(),
    };

    getAccessTokenSilently().then((result) => {
      localStorage.setItem('token', result);
    });

    const axiosConfig = {
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      },
    };

    axios
      .put(`${devUrl}/properties/column/${columnId}/`, request, axiosConfig)

      .catch((error) => {
        console.log(error);
      });
  }

  return (
    <Box
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      {!isColumnTitleFocused ? (
        <Typography
          variant='h6'
          noWrap={true}
          name='editColumnTitle'
          style={{
            maxWidth: '200px',
          }}
          onClick={() => {
            setIsColumnTitleFocused(true);
          }}
        >
          {columnData.editColumnTitle}
        </Typography>
      ) : (
        <TextField
          size='small'
          variant='standard'
          autoFocus
          name='editColumnTitle'
          autoComplete='off'
          inputProps={{ maxLength: 180 }}
          value={columnData.editColumnTitle || ''}
          helperText={
            columnData.editColumnTitle
              ? ''
              : 'Column title can not be left blank'
          }
          error={columnData.editColumnTitle ? false : true}
          onChange={handleColumnTitleChange}
          onBlur={handleBlurChange}
          onKeyDown={handleKeyChange}
        />
      )}

      <ColumnMenuDropdown
        columnId={id}
        name={title}
        handleEditColumnTitle={handleEditColumnTitleDropdownClick}
      />
    </Box>
  );
}
