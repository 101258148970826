import Loading from '../components/Loading/Loading';
import Home from '../routes/Home/Home';
import { useAuth0 } from '@auth0/auth0-react';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import NotFound from '../components/Errors/NotFound';
import UserProfile from '../routes/UserProfile/UserProfile';
import Board from '../components/Board/Board';
import { ThemeProvider } from '@mui/material';
import { customTheme } from '../utils/customStyles';
import { useTheme } from '@mui/material/styles';
import AboutPage from '../routes/AboutPage/AboutPage';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import habituntLogo from '../assets/habitunt-logo.png';
import Link from '@mui/material/Link';
import TermsAndConditions from '../routes/TermsAndConditions/TermsAndConditions';
import PrivacyPolicy from '../routes/PrivacyPolicy/PrivacyPolicy';

export default function App() {
  const { isLoading, error, isAuthenticated } = useAuth0();
  const outerTheme = useTheme();
  const url = process.env.REACT_APP_URL;

  if (isLoading) {
    return <Loading />;
  }

  if (!isAuthenticated && error) {
    return (
      <ThemeProvider theme={customTheme(outerTheme)}>
        <Box
          display='flex'
          flexDirection='column'
          justifyContent='center'
          alignItems='center'
          textAlign='center'
          minHeight='100vh'
        >
          <Box sx={{ m: 1 }}>
            {window.location.href.match('error=access_denied') ? (
              <Box>
                <Box>
                  <Typography variant='h5'>
                    Please verify your email address.
                  </Typography>
                </Box>
                <Box>
                  <Typography variant='subtitle1'>
                    Check the inbox of the email address that you used to sign
                    up.
                  </Typography>
                </Box>
              </Box>
            ) : (
              <Box>
                <Typography variant='h5'>
                  Sorry, an unexpected error occured.
                </Typography>
              </Box>
            )}
          </Box>
          <Box>
            <Link href={url} color='inherit'>
              <img src={habituntLogo} alt='habitunt-logo' />
            </Link>
          </Box>
        </Box>
      </ThemeProvider>
    );
  }

  return (
    <ThemeProvider theme={customTheme(outerTheme)}>
      <Router>
        <Routes>
          <Route path='/' element={<Home />} errorElement={<NotFound />} />
          <Route
            path='/board'
            element={<Board />}
            errorElement={<NotFound />}
          />
          <Route
            path='/account'
            element={<UserProfile />}
            errorElement={<NotFound />}
          />
          <Route
            path='/about'
            element={<AboutPage />}
            errorElement={<NotFound />}
          />
          <Route
            path='/terms-and-conditions'
            element={<TermsAndConditions />}
            errorElement={<NotFound />}
          />
          <Route
            path='/privacy-policy'
            element={<PrivacyPolicy />}
            errorElement={<NotFound />}
          />
          <Route path='*' element={<NotFound />} />
        </Routes>
      </Router>
    </ThemeProvider>
  );
}
