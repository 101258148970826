import { Box, Button, Grid, Typography } from '@mui/material';
import './Intro.scss';
import { useAuth0 } from '@auth0/auth0-react';
import { Link } from 'react-router-dom';
import house from '../../assets/house_searching.svg';
import {
  introTitleStyles,
  introTextStyles,
  introButtonStyles,
  introContainerStyles,
  introInfoStyles,
  introButtonContainerStyles,
  introImageContainerStyles,
} from './IntroMediaQuery';

export default function Intro() {
  const { isAuthenticated, loginWithRedirect } = useAuth0();
  const localUrl = process.env.REACT_APP_URL;

  return (
    <Box>
      <Grid container className='intro-container' sx={introContainerStyles}>
        <Grid item xs={12} lg={6} xl={5} className='intro-info'>
          <Box sx={introInfoStyles}>
            <Typography
              variant='h3'
              className='intro-title'
              sx={introTitleStyles}
            >
              Stay organised while searching for your future home
            </Typography>
            <Typography
              variant='h5'
              className='intro-text'
              sx={introTextStyles}
            >
              Habitunt keeps your property search in one place, whether you are
              buying or renting. A better alternative for property spreadsheets.
            </Typography>

            <Box
              className='intro-button-container'
              sx={introButtonContainerStyles}
            >
              {!isAuthenticated ? (
                <Button
                  className='intro-button'
                  variant='contained'
                  sx={introButtonStyles}
                  onClick={() =>
                    loginWithRedirect({
                      authorizationParams: {
                        redirect_uri: `${localUrl}/board`,
                      },
                    })
                  }
                >
                  Get Habitunt - It's Free!
                </Button>
              ) : (
                <Link
                  to={`/board`}
                  style={{
                    textDecoration: 'none',
                    color: 'inherit',
                  }}
                >
                  <Button
                    className='intro-button'
                    variant='contained'
                    sx={introButtonStyles}
                  >
                    Go to your board
                  </Button>
                </Link>
              )}
            </Box>
          </Box>
        </Grid>
        <Grid sx={introImageContainerStyles} item xs={12} md={10} lg={6} xl={7}>
          <img className='intro-image' alt='Board sample' src={house} />
        </Grid>
      </Grid>
    </Box>
  );
}
