import spinner from '../../assets/spinner.svg';

export default function Loading() {
  return (
    <div
      className='spinner'
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
      }}
    >
      <img src={spinner} alt='Loading' />
    </div>
  );
}
