export const buyPropertyType = [
  {
    value: 'Detached',
    label: 'Detached',
  },
  {
    value: 'Semi-detached',
    label: 'Semi-detached',
  },
  {
    value: 'Terraced',
    label: 'Terraced',
  },
  {
    value: 'Flat',
    label: 'Flat',
  },
  {
    value: 'Bungalow',
    label: 'Bungalow',
  },
  {
    value: 'Land',
    label: 'Land',
  },
  {
    value: 'Park Home',
    label: 'Park Home',
  },
  {
    value: 'Commercial Property',
    label: 'Commercial Property',
  },
  {
    value: 'Other',
    label: 'Other',
  },
];

export const rentPropertyType = [
  {
    value: 'Detached',
    label: 'Detached',
  },
  {
    value: 'Semi-detached',
    label: 'Semi-detached',
  },
  {
    value: 'Terraced',
    label: 'Terraced',
  },
  {
    value: 'Flat',
    label: 'Flat',
  },
  {
    value: 'Student Halls',
    label: 'Student Halls',
  },
  {
    value: 'Bungalow',
    label: 'Bungalow',
  },
  {
    value: 'Land',
    label: 'Land',
  },
  {
    value: 'Park Home',
    label: 'Park Home',
  },
  {
    value: 'Commercial Property',
    label: 'Commercial Property',
  },
  {
    value: 'Other',
    label: 'Other',
  },
];

export const TenureType = [
  {
    value: 'Freehold',
    label: 'Freehold',
  },
  {
    value: 'Share of Freehold',
    label: 'Share of Freehold',
  },
  {
    value: 'Leasehold',
    label: 'Leasehold',
  },
  {
    value: 'Commonhold',
    label: 'Commonhold',
  },
  {
    value: 'Shared Ownership',
    label: 'Shared Ownership',
  },
];

export const SizeType = [
  {
    value: 'sq. m.',
    label: 'sq. m.',
  },
  {
    value: 'sq. ft.',
    label: 'sq. ft.',
  },
];

export const rentPriceTypeDropdown = [
  {
    value: 'pcm',
    label: 'pcm',
  },
  {
    value: 'pw',
    label: 'pw',
  },
];

export const letType = [
  {
    value: 'Long Term',
    label: 'Long Term',
  },
  {
    value: 'Short Term',
    label: 'Short Term',
  },
];

export const furnishType = [
  {
    value: 'Furnished',
    label: 'Furnished',
  },
  {
    value: 'Part-furnished',
    label: 'Part-furnished',
  },
  {
    value: 'Unfurnished',
    label: 'Unfurnished',
  },
];
