import { Box, Typography } from '@mui/material';
import NavBar from '../NavBar/NavBar';
import './PrivacyPolicy.scss';
import { useEffect } from 'react';

export default function PrivacyPolicy() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Box>
      <NavBar />
      <Box className='privacy-container'>
        <Typography variant='h4' className='main-title'>
          PRIVACY POLICY
        </Typography>
        <Typography variant='body1'>
          This privacy policy applies between you, the User of this Website, and
          Habitunt, the owner and provider of this Website. Habitunt takes the
          privacy of your information very seriously. This privacy policy
          applies to our use of any and all Data collected by us or provided by
          you in relation to your use of the Website.
        </Typography>
        <br />
        <Typography variant='body1'>
          This privacy policy should be read alongside, and in addition to, our
          Terms and Conditions, which can be found at:
          www.habitunt.com/terms-and-conditions.
        </Typography>
        <br />
        <Typography variant='body1' className='bold-text'>
          Please read this privacy policy carefully.
        </Typography>
        <br />
        <Typography variant='h6' className='sub-title'>
          Definitions and interpretation
        </Typography>
        <Box className='numbered-list'>
          <Typography variant='body1'>
            1. In this privacy policy, the following definitions are used:
          </Typography>
          <br />
          <Typography variant='body1' className='bold-text'>
            Data
          </Typography>
          <Typography variant='body1'>
            Collectively all information that you submit to Habitunt via the
            Website. This definition incorporates, where applicable, the
            definitions provided in the Data Protection Laws;
          </Typography>
          <br />
          <Typography variant='body1' className='bold-text'>
            Cookies
          </Typography>
          <Typography variant='body1'>
            A small text file placed on your computer by this Website when you
            visit certain parts of the Website and/or when you use certain
            features of the Website. Details of the cookies used by this Website
            are set out in the clause below (
            <span className='bold-text'>Cookies</span>);
          </Typography>
          <br />
          <Typography variant='body1' className='bold-text'>
            Data Protection Laws
          </Typography>
          <Typography variant='body1'>
            Any applicable law relating to the processing of personal Data,
            including but not limited to the GDPR, and any national implementing
            and supplementary laws, regulations and secondary legislation;
          </Typography>
          <br />
          <Typography variant='body1' className='bold-text'>
            GDPR
          </Typography>
          <Typography variant='body1'>
            The UK General Data Protection Regulation;
          </Typography>
          <br />
          <Typography variant='body1'>
            <span className='bold-text'>Habitunt</span>,{' '}
            <span className='bold-text'>we</span> or{' '}
            <span className='bold-text'>us</span>
          </Typography>
          <Typography variant='body1'>Habitunt;</Typography>
          <br />
          <Typography variant='body1' className='bold-text'>
            UK and EU Cookie Law
          </Typography>
          <Typography variant='body1'>
            The Privacy and Electronic Communications (EC Directive) Regulations
            2003 as amended by the Privacy and Electronic Communications (EC
            Directive) (Amendment) Regulations 2011 & the Privacy and Electronic
            Communications (EC Directive) (Amendment) Regulations 2018;
          </Typography>
          <br />
          <Typography variant='body1'>
            <span className='bold-text'>User</span> or{' '}
            <span className='bold-text'>you</span>
          </Typography>
          <Typography variant='body1'>
            Any third party that accesses the Website and is not either (i)
            employed by Habitunt and acting in the course of their employment or
            (ii) engaged as a consultant or otherwise providing services to
            Habitunt and accessing the Website in connection with the provision
            of such services;
          </Typography>
          <br />
          <Typography variant='body1' className='bold-text'>
            Website
          </Typography>
          <Typography variant='body1'>
            The website that you are currently using, www.habitunt.com, and any
            sub-domains of this site unless expressly excluded by their own
            terms and conditions.
          </Typography>
        </Box>
        <br />
        <Box className='numbered-list'>
          <Typography variant='body1'>
            2. In this privacy policy, unless the context requires a different
            interpretation:
          </Typography>
          <Box className='numbered-sub-list'>
            <Typography variant='body1'>
              a. the singular includes the plural and vice versa;
            </Typography>
            <Typography variant='body1'>
              b. references to sub-clauses, clauses, schedules or appendices are
              to sub-clauses, clauses, schedules or appendices of this privacy
              policy;
            </Typography>
            <Typography variant='body1'>
              c. a reference to a person includes firms, companies, government
              entities, trusts and partnerships;
            </Typography>
            <Typography variant='body1'>
              d. "including" is understood to mean "including without
              limitation";
            </Typography>
            <Typography variant='body1'>
              e. reference to any statutory provision includes any modification
              or amendment of it;
            </Typography>
            <Typography variant='body1'>
              f. the headings and sub-headings do not form part of this privacy
              policy.
            </Typography>
          </Box>
        </Box>
        <br />
        <Typography variant='h6' className='sub-title'>
          Scope of this privacy policy
        </Typography>
        <Box className='numbered-list'>
          <Typography variant='body1'>
            3. This privacy policy applies only to the actions of Habitunt and
            Users with respect to this Website. It does not extend to any
            websites that can be accessed from this Website including, but not
            limited to, any links we may provide to social media websites.
          </Typography>
          <Typography variant='body1'>
            4. For purposes of the applicable Data Protection Laws, Habitunt is
            the "data controller". This means that Habitunt determines the
            purposes for which, and the manner in which, your Data is processed.
          </Typography>
        </Box>
        <br />
        <Typography variant='h6' className='sub-title'>
          Data collected
        </Typography>
        <Box className='numbered-list'>
          <Typography variant='body1'>
            5. We may collect the following Data, which includes personal Data,
            from you:
          </Typography>
          <Box className='numbered-sub-list'>
            <Typography variant='body1'>
              a. contact Information such as email addresses and telephone
              numbers;
            </Typography>
            <Typography variant='body1'>
              b. IP address (automatically collected);
            </Typography>
            <Typography variant='body1'>
              c. web browser type and version (automatically collected);
            </Typography>
            <Typography variant='body1'>
              d. operating system (automatically collected);
            </Typography>
            <Typography variant='body1'>
              e. a list of URLs starting with a referring site, your activity on
              this Website, and the site you exit to (automatically collected);
            </Typography>
          </Box>
          <Typography variant='body1'>
            in each case, in accordance with this privacy policy.
          </Typography>
        </Box>
        <br />
        <Typography variant='h6' className='sub-title'>
          How we collect Data
        </Typography>
        <Box className='numbered-list'>
          <Typography variant='body1'>
            6. We collect Data in the following ways:
          </Typography>
          <Box className='numbered-sub-list'>
            <Typography variant='body1'>
              a. data is given to us by you; and
            </Typography>
            <Typography variant='body1'>
              b. data is collected automatically.
            </Typography>
          </Box>
        </Box>
        <br />
        <Typography variant='h6' className='sub-title'>
          Data that is given to us by you
        </Typography>
        <Box className='numbered-list'>
          <Typography variant='body1'>
            7. Habitunt will collect your Data in a number of ways, for example:
          </Typography>
          <Box className='numbered-sub-list'>
            <Typography variant='body1'>
              a. when you contact us through the Website, by telephone, post,
              e-mail or through any other means;
            </Typography>
            <Typography variant='body1'>
              b. when you use our services;
            </Typography>
          </Box>
          <Typography variant='body1'>
            in each case, in accordance with this privacy policy.
          </Typography>
        </Box>
        <br />
        <Typography variant='h6' className='sub-title'>
          Data that is collected automatically
        </Typography>
        <Box className='numbered-list'>
          <Typography variant='body1'>
            8. To the extent that you access the Website, we will collect your
            Data automatically, for example:
          </Typography>
          <Box className='numbered-sub-list'>
            <Typography variant='body1'>
              a. we automatically collect some information about your visit to
              the Website. This information helps us to make improvements to
              Website content and navigation, and includes your IP address, the
              date, times and frequency with which you access the Website and
              the way you use and interact with its content.
            </Typography>
            <Typography variant='body1'>
              b. we will collect your Data automatically via cookies, in line
              with the cookie settings on your browser. For more information
              about cookies, and how we use them on the Website, see the section
              below, headed "Cookies".
            </Typography>
          </Box>
        </Box>
        <br />
        <Typography variant='h6' className='sub-title'>
          Our use of Data
        </Typography>
        <Box className='numbered-list'>
          <Typography variant='body1'>
            9. Any or all of the above Data may be required by us from time to
            time in order to provide you with the best possible service and
            experience when using our Website. Specifically, Data may be used by
            us for the following reasons:
          </Typography>
          <Box className='numbered-sub-list'>
            <Typography variant='body1'>
              a. improvement of our products / services;
            </Typography>
          </Box>
          <Typography variant='body1'>
            in each case, in accordance with this privacy policy.
          </Typography>
          <Typography variant='body1'>
            10. We may use your Data for the above purposes if we deem it
            necessary to do so for our legitimate interests. If you are not
            satisfied with this, you have the right to object in certain
            circumstances (see the section headed "Your rights" below).
          </Typography>
          <Typography variant='body1'>
            11. We may use your Data to show you Habitunt adverts and other
            content on other websites. If you do not want us to use your data to
            show you Habitunt adverts and other content on other websites,
            please turn off the relevant cookies (please refer to the section
            headed "Cookies" below).
          </Typography>
        </Box>
        <br />
        <Typography variant='h6' className='sub-title'>
          Who we share Data with
        </Typography>
        <Box className='numbered-list'>
          <Typography variant='body1'>
            12. We may share your Data with the following groups of people for
            the following reasons:
          </Typography>
          <Box className='numbered-sub-list'>
            <Typography variant='body1'>
              a. third party service providers who provide services to us which
              require the processing of personal data - to perform ad
              measurement services;
            </Typography>
          </Box>
          <Typography variant='body1'>
            in each case, in accordance with this privacy policy.
          </Typography>
        </Box>
        <br />
        <Typography variant='h6' className='sub-title'>
          Keeping Data secure
        </Typography>
        <Box className='numbered-list'>
          <Typography variant='body1'>
            13. We will use technical and organisational measures to safeguard
            your Data, for example:
          </Typography>
          <Box className='numbered-sub-list'>
            <Typography variant='body1'>
              a. access to your account is controlled by a password and a user
              name that is unique to you.
            </Typography>
            <Typography variant='body1'>
              b. we store your Data on secure servers.
            </Typography>
          </Box>
          <Typography variant='body1'>
            14. Technical and organisational measures include measures to deal
            with any suspected data breach. If you suspect any misuse or loss or
            unauthorised access to your Data, please let us know immediately by
            contacting us via this e-mail address: info@habitunt.com.
          </Typography>
          <Typography variant='body1'>
            15. If you want detailed information from Get Safe Online on how to
            protect your information and your computers and devices against
            fraud, identity theft, viruses and many other online problems,
            please visit www.getsafeonline.org. Get Safe Online is supported by
            HM Government and leading businesses.
          </Typography>
        </Box>
        <br />
        <Typography variant='h6' className='sub-title'>
          Data retention
        </Typography>
        <Box className='numbered-list'>
          <Typography variant='body1'>
            16. Unless a longer retention period is required or permitted by
            law, we will only hold your Data on our systems for the period
            necessary to fulfil the purposes outlined in this privacy policy or
            until you request that the Data be deleted.
          </Typography>
          <Typography variant='body1'>
            17. Even if we delete your Data, it may persist on backup or
            archival media for legal, tax or regulatory purposes.
          </Typography>
        </Box>
        <br />
        <Typography variant='h6' className='sub-title'>
          Your rights
        </Typography>
        <Box className='numbered-list'>
          <Typography variant='body1'>
            18. You have the following rights in relation to your Data:
          </Typography>
          <Box className='numbered-sub-list'>
            <Typography variant='body1'>
              a. <span className='bold-text'>Right to access - </span>the right
              to request (i) copies of the information we hold about you at any
              time, or (ii) that we modify, update or delete such information.
              If we provide you with access to the information we hold about
              you, we will not charge you for this, unless your request is
              "manifestly unfounded or excessive." Where we are legally
              permitted to do so, we may refuse your request. If we refuse your
              request, we will tell you the reasons why.
            </Typography>
            <Typography variant='body1'>
              b. <span className='bold-text'>Right to correct - </span>the right
              to have your Data rectified if it is inaccurate or incomplete.
            </Typography>
            <Typography variant='body1'>
              c. <span className='bold-text'>Right to erase - </span>the right
              to request that we delete or remove your Data from our systems.
            </Typography>
            <Typography variant='body1'>
              e.{' '}
              <span className='bold-text'>
                Right to restrict our use of your Data -{' '}
              </span>
              tthe right to "block" us from using your Data or limit the way in
              which we can use it.
            </Typography>
            <Typography variant='body1'>
              e. <span className='bold-text'>Right to data portability - </span>
              tthe right to request that we move, copy or transfer your Data.
            </Typography>
            <Typography variant='body1'>
              f. <span className='bold-text'>Right to object - </span>the right
              to object to our use of your Data including where we use it for
              our legitimate interests.
            </Typography>
          </Box>
          <Typography variant='body1'>
            19. To make enquiries, exercise any of your rights set out above, or
            withdraw your consent to the processing of your Data (where consent
            is our legal basis for processing your Data), please contact us via
            this e-mail address: info@habitunt.com.
          </Typography>
          <Typography variant='body1'>
            20. If you are not satisfied with the way a complaint you make in
            relation to your Data is handled by us, you may be able to refer
            your complaint to the relevant data protection authority. For the
            UK, this is the Information Commissioner's Office (ICO). The ICO's
            contact details can be found on their website at
            https://ico.org.uk/.
          </Typography>
          <Typography variant='body1'>
            21. It is important that the Data we hold about you is accurate and
            current. Please keep us informed if your Data changes during the
            period for which we hold it.
          </Typography>
        </Box>
        <br />
        <Typography variant='h6' className='sub-title'>
          Links to other websites
        </Typography>
        <Box className='numbered-list'>
          <Typography variant='body1'>
            22. This Website may, from time to time, provide links to other
            websites. We have no control over such websites and are not
            responsible for the content of these websites. This privacy policy
            does not extend to your use of such websites. You are advised to
            read the privacy policy or statement of other websites prior to
            using them.
          </Typography>
        </Box>
        <br />
        <Typography variant='h6' className='sub-title'>
          Changes of business ownership and control
        </Typography>
        <Box className='numbered-list'>
          <Typography variant='body1'>
            23. Habitunt may, from time to time, expand or reduce our business
            and this may involve the sale and/or the transfer of control of all
            or part of Habitunt. Data provided by Users will, where it is
            relevant to any part of our business so transferred, be transferred
            along with that part and the new owner or newly controlling party
            will, under the terms of this privacy policy, be permitted to use
            the Data for the purposes for which it was originally supplied to
            us.
          </Typography>
          <Typography variant='body1'>
            24. We may also disclose Data to a prospective purchaser of our
            business or any part of it.
          </Typography>
          <Typography variant='body1'>
            25. In the above instances, we will take steps with the aim of
            ensuring your privacy is protected.
          </Typography>
        </Box>
        <br />
        <Typography variant='h6' className='sub-title'>
          Children
        </Typography>
        <Box className='numbered-list'>
          <Typography variant='body1'>
            26. We do not intend to solicit or collect Personal Information from
            anyone under the age of 18. If you are under 18, do not enter
            information on this Website or engage our services. If you believe a
            child or yours under the age of 18 has entered Personal Information,
            please contact us via this e-mail address: info@habitunt.com to have
            the data removed and terminate the child's account.
          </Typography>
        </Box>
        <br />
        <Typography variant='h6' className='sub-title'>
          Cookies
        </Typography>
        <Box className='numbered-list'>
          <Typography variant='body1'>
            27. This Website may place and access certain Cookies on your
            computer. Habitunt uses Cookies to improve your experience of using
            the Website. Habitunt has carefully chosen these Cookies and has
            taken steps to ensure that your privacy is protected and respected
            at all times.
          </Typography>
          <Typography variant='body1'>
            28. All Cookies used by this Website are used in accordance with
            current UK and EU Cookie Law.
          </Typography>
          <Typography variant='body1'>
            29. Before the Website places Cookies on your computer, you will be
            presented with a message bar requesting your consent to set those
            Cookies. By giving your consent to the placing of Cookies, you are
            enabling Habitunt to provide a better experience and service to you.
            You may, if you wish, deny consent to the placing of Cookies;
            however certain features of the Website may not function fully or as
            intended.
          </Typography>
          <Typography variant='body1'>
            30. This Website may place the following Cookies:
          </Typography>
          <br />
          <Typography variant='body1' className='bold-text'>
            Strictly necessary cookies
          </Typography>
          <Typography variant='body1'>
            These are cookies that are required for the operation of our
            Website. They include, for example, cookies that enable you to log
            into secure areas of our Website, use a shopping cart or make use of
            e-billing services.
          </Typography>
          <br />
          <Typography variant='body1' className='bold-text'>
            Analytical/performance cookies
          </Typography>
          <Typography variant='body1'>
            They allow us to recognise and count the number of visitors and to
            see how visitors move around our Website when they are using it.
            This helps us to improve the way our Website works, for example, by
            ensuring that users are finding what they are looking for easily.
          </Typography>
          <br />
          <Typography variant='body1' className='bold-text'>
            Functionality cookies
          </Typography>
          <Typography variant='body1'>
            These are used to recognise you when you return to our Website. This
            enables us to personalise our content for you, greet you by name and
            remember your preferences (for example, your choice of language or
            region). By using the Website, you agree to our placement of
            functionality cookie.
          </Typography>
          <br />
          <Typography variant='body1' className='bold-text'>
            Targeting cookies
          </Typography>
          <Typography variant='body1'>
            These cookies record your visit to our Website, the pages you have
            visited and the links you have followed. We will use this
            information to make our Website and the advertising displayed on it
            more relevant to your interests. We may also share this information
            with third parties for this purpose.
          </Typography>
          <br />
          <Typography variant='body1'>
            31. You can find a list of Cookies that we use in the Cookies
            Schedule.
          </Typography>
          <Typography variant='body1'>
            32. We give you control over which Cookies we use. You can adjust
            your cookies preferences at any time at: www.habitunt.com.
          </Typography>
          <Typography variant='body1'>
            33. You can also choose to enable or disable Cookies in your
            internet browser. By default, most internet browsers accept Cookies
            but this can be changed. For further details, please see the help
            menu in your internet browser. You can switch off Cookies at any
            time, however, you may lose any information that enables you to
            access the Website more quickly and efficiently.
          </Typography>
          <Typography variant='body1'>
            34. You can choose to delete Cookies at any time; however, you may
            lose any information that enables you to access the Website more
            quickly and efficiently including, but not limited to,
            personalisation settings.
          </Typography>
          <Typography variant='body1'>
            35. It is recommended that you ensure that your internet browser is
            up-to-date and that you consult the help and guidance provided by
            the developer of your internet browser if you are unsure about
            adjusting your privacy settings.
          </Typography>
          <Typography variant='body1'>
            36. For more information generally on cookies, including how to
            disable them, please refer to aboutcookies.org. You will also find
            details on how to delete cookies from your computer.
          </Typography>
        </Box>
        <br />
        <Typography variant='h6' className='sub-title'>
          Cookies
        </Typography>
        <Typography variant='body1'>
          Below is a list of the cookies that we use. We have tried to ensure
          this is complete and up to date, but if you think that we have missed
          a cookie or there is any discrepancy, please let us know.
        </Typography>
        <br />
        <Typography variant='body1' className='bold-text'>
          Strictly necessary
        </Typography>
        <Typography variant='body1'>
          We use the following strictly necessary cookies:
        </Typography>
        <Box className='numbered-list'>
          <Typography variant='body1' className='bold-text'>
            Session
          </Typography>
          <Typography variant='body1'>
            We use this session cookie to remember you and maintain your session
            whilst you are using our Website.
          </Typography>
        </Box>
        <br />
        <Typography variant='body1' className='bold-text'>
          Analytical/Performance/Functionality
        </Typography>
        <Typography variant='body1'>
          We use the following analytical/performance/functionality cookies:
        </Typography>
        <Box className='numbered-list'>
          <Typography variant='body1' className='bold-text'>
            Analytics
          </Typography>
          <Typography variant='body1'>
            We use Google Analytics, a web analytics service provided by Google,
            Inc. (“Google”). Google Analytics uses cookies to help us analyze
            how users use the Website and enhance your experience when you use
            the Website.
          </Typography>
        </Box>
        <br />
        <Typography variant='body1' className='bold-text'>
          Targeting
        </Typography>
        <Typography variant='body1'>
          We use the following targeting cookies:
        </Typography>
        <Box className='numbered-list'>
          <Typography variant='body1' className='bold-text'>
            Advertising Cookies
          </Typography>
          <Typography variant='body1'>
            Our Website uses Google Ads, a service provided by Google that
            allows us to display targeted advertisements to users. Google Ads
            uses cookies to track and serve ads to users based on their
            interests and online behavior. By using our Website, you consent to
            the use of Google Ads cookies as described in this policy.
          </Typography>
        </Box>
        <br />
        <Typography variant='h6' className='sub-title'>
          General
        </Typography>
        <Box className='numbered-list'>
          <Typography variant='body1'>
            37. You may not transfer any of your rights under this privacy
            policy to any other person. We may transfer our rights under this
            privacy policy where we reasonably believe your rights will not be
            affected.
          </Typography>
          <Typography variant='body1'>
            38. If any court or competent authority finds that any provision of
            this privacy policy (or part of any provision) is invalid, illegal
            or unenforceable, that provision or part-provision will, to the
            extent required, be deemed to be deleted, and the validity and
            enforceability of the other provisions of this privacy policy will
            not be affected.
          </Typography>
          <Typography variant='body1'>
            39. Unless otherwise agreed, no delay, act or omission by a party in
            exercising any right or remedy will be deemed a waiver of that, or
            any other, right or remedy.
          </Typography>
          <Typography variant='body1'>
            40. This Agreement will be governed by and interpreted according to
            the law of England and Wales. All disputes arising under the
            Agreement will be subject to the exclusive jurisdiction of the
            English and Welsh courts.
          </Typography>
        </Box>
        <br />
        <Typography variant='h6' className='sub-title'>
          Changes to this privacy policy
        </Typography>
        <Box className='numbered-list'>
          <Typography variant='body1'>
            41. Habitunt reserves the right to change this privacy policy as we
            may deem necessary from time to time or as may be required by law.
            Any changes will be immediately posted on the Website and you are
            deemed to have accepted the terms of the privacy policy on your
            first use of the Website following the alterations. You may contact
            Habitunt by email at info@habitunt.com.
          </Typography>
        </Box>
        <br />
        <Typography variant='h6' className='sub-title'>
          Attribution
        </Typography>
        <Box className='numbered-list'>
          <Typography variant='body1'>
            42. This privacy policy was created using a document from Rocket
            Lawyer (https://www.rocketlawyer.com/gb/en).
          </Typography>
        </Box>
        <br />
        <Typography variant='body1'>
          This privacy policy was created on{' '}
          <span className='bold-text'>27 July 2023</span>.
        </Typography>
      </Box>
    </Box>
  );
}
