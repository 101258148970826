import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import { Link } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { saveBoardMetadata } from '../../store/properties';
import { Popover, Tooltip, Typography } from '@mui/material';
import './NavBar.scss';
import {
  MenuIconStyles,
  NavButtonStyles,
  NavTitleButtonStyles,
} from './NavBarMediaQuery';
import NavBarMenuItem from '../NavBarMenuItem/NavBarMenuItem';
import habituntLogo from '../../assets/habitunt-logo.png';

export default function NavBar() {
  const { isAuthenticated, loginWithRedirect, logout } = useAuth0();
  const boardMetadata = useSelector((state) => state.properties.boardMetadata);
  let { title } = boardMetadata || {};
  const { getAccessTokenSilently } = useAuth0();
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  const devUrl = process.env.REACT_APP_API_URL;
  const localUrl = process.env.REACT_APP_URL;

  useEffect(() => {
    if (!isAuthenticated) {
      return;
    }
    getAccessTokenSilently().then((result) => {
      localStorage.setItem('token', result);
    });

    const axiosConfig = {
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      },
    };

    axios
      .get(`${devUrl}/board/`, axiosConfig)
      .then((response) => {
        const data = response.data;
        dispatch(saveBoardMetadata({ boardMetadata: data[0] }));
      })
      .catch((error) => {
        console.log(error);
      });
  }, [dispatch]);

  const logoutWithRedirect = () =>
    logout({
      logoutParams: {
        returnTo: window.location.origin,
      },
    });

  return (
    <AppBar position="sticky" color="default" className="appbar-container">
      <Toolbar className="toolbar">
        <Box className="toolbar-left">
          <Box>
            <Link to={`/`} style={{ textDecoration: 'none', color: 'inherit' }}>
              <img src={habituntLogo} alt="habitunt-logo" />
            </Link>
          </Box>
        </Box>

        <Box className="toolbar-right">
          {!isAuthenticated && (
            <Box>
              <Link
                to={`/about`}
                style={{ textDecoration: 'none', color: 'inherit' }}
              >
                <Button color="inherit" sx={NavButtonStyles}>
                  <Typography noWrap={true}>About</Typography>
                </Button>
              </Link>
            </Box>
          )}
          {isAuthenticated && (
            <Box>
              <Link
                to={`/board`}
                style={{
                  textDecoration: 'none',
                  color: 'inherit',
                }}
              >
                <Tooltip title={title}>
                  <Button
                    color="inherit"
                    style={{ maxWidth: '310px' }}
                    sx={NavTitleButtonStyles}
                  >
                    <Typography noWrap={true}>{title}</Typography>
                  </Button>
                </Tooltip>
              </Link>
            </Box>
          )}
          {isAuthenticated && (
            <Box>
              <Link
                to={`/account`}
                style={{ textDecoration: 'none', color: 'inherit' }}
              >
                <Button color="inherit" sx={NavButtonStyles}>
                  <Typography noWrap={true}>Account</Typography>
                </Button>
              </Link>
            </Box>
          )}
          <Box>
            {!isAuthenticated && (
              <Button
                color="inherit"
                sx={NavButtonStyles}
                onClick={() =>
                  loginWithRedirect({
                    authorizationParams: {
                      redirect_uri: `${localUrl}/board`,
                    },
                  })
                }
              >
                <Typography noWrap={true}>Login/Sign Up</Typography>
              </Button>
            )}
            {isAuthenticated && (
              <Button
                color="inherit"
                sx={NavButtonStyles}
                onClick={() => logoutWithRedirect()}
              >
                <Typography noWrap={true}>Log out</Typography>
              </Button>
            )}
          </Box>
          <Box sx={MenuIconStyles}>
            <IconButton
              aria-describedby={id}
              size="large"
              edge="start"
              color="inherit"
              aria-label="menu"
              onClick={handleClick}
            >
              <MenuIcon />
            </IconButton>
            <Popover
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
            >
              <NavBarMenuItem />
            </Popover>
          </Box>
        </Box>
      </Toolbar>
    </AppBar>
  );
}
